<template>
  <div class="knowledge-item-view">
    <div class="kn-news-top" :style="getChannelBackgroundColor">
      <div class="kn-news-classes">
        <span class="kn-news-class">
          {{ getClsfrs }}
        </span>
        <span class="nw-news-class">
          , {{ getCountries }}
        </span>
      </div>
    </div>

    <h1 class="kn-news-title" v-html="titleItem().svl" />

    <div class="kn-news-preview">

      <div class="kn-news-text" v-html="annotationItem().svl" />

      <img v-if="multimediaItem().svl != ''" class="kn-news-image" :src="multimediaItem().svl" />

    </div>

    <div v-if="links.length > 0 || files.length > 0 || images.length > 0" class="hb-entity-select-container">

      <div class="hb-entity-select-controls-light">
        <span v-if="isHideEntityControl" class="hb-button-entity-control-light" @click="toggleHideEntityCotrol()" title="Show key info">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_tHEC)">
            <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="rgb(0,74,99)" stroke-opacity="1" stroke-width="2"/>
            <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="rgb(0,74,99)" stroke-opacity="1" stroke-width="2"/>
            </g>
            <defs>
            <clipPath id="clip0_tHEC">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </span>
        <span v-else class="hb-button-entity-control-light" @click="toggleHideEntityCotrol()" title="Hide key info">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_4)">
            <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="rgb(0,74,99)" stroke-opacity="1" stroke-width="2"/>
            <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="rgb(0,74,99)" stroke-opacity="1" stroke-width="2"/>
            </g>
            <defs>
            <clipPath id="clip0_1_4">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </span>

        <span v-if="files.length > 0" class="vw-options__value-contaier">
          <span><input v-model="msrsGroup.filesView" type="checkbox" name="filesView"/></span>
          <label class="checkbox-label" for="filesView">Files {{ filesCount() }}</label>
        </span>

        <span v-if="images.length > 0" class="vw-options__value-contaier">
          <span><input v-model="msrsGroup.imagesView" type="checkbox" name="imagesView"/></span>
          <label class="checkbox-label" for="imagesView">Images {{ imagesCount() }}</label>
        </span>

        <span v-if="links.length > 0" class="vw-options__value-contaier">
          <span><input v-model="msrsGroup.linksView" type="checkbox" name="linksView"/></span>
          <label class="checkbox-label" for="linksView">Links {{ linksCount() }}</label>
        </span>

      </div>

      <div v-if="!isHideEntityControl" class="hb-entity-select-items">
        <FilesCell :files="getFiles" v-if="files.length > 0 && msrsGroup.filesView"/>
        <ImagesCell :images="getImages" v-if="images.length > 0  && msrsGroup.imagesView"/>
        <LinksCell :links="getLinks" v-if="links.length > 0  && msrsGroup.linksView"/>
      </div>

    </div>

    <Editor :modelValue="htmlItem()" :elId="item.id" :mode="mode" :height="height"/>

  </div>
</template>

<script>
import Editor from '@/components/editor/editor.vue';
import LinksCell from '@/components/links/links-cell.vue';
import FilesCell from '@/components/files/files-cell.vue';
import ImagesCell from '@/components/images/images-cell.vue';
import ENV from '@/costants/constants';

export default {
  name: 'KnowldgeItemView',
  props: ['item'],
  data () {
    return {
      mode: 'view',
//      id: this.item.id,
      height: 300,

      links: [],
      files: [],
      images: [],
      msrsGroup: {
        view: false,
        filesView: false,
        linksView: false,
        imagesView: false,
      },
    }
  },
  components: {
    LinksCell, FilesCell,
    ImagesCell,
    Editor,
  },

  created () {
    //console.log('item.msrs[0].svl: ', this.item.msrs[0].svl)
    const linkClctn = this.item.msrs.filter((item) => (item.vid == ENV.MSR_LINK_ITEMS_ID))[0].clctn
    
    linkClctn.forEach( (item) => {
      let link = {};
      
      link.url = item.svl                        
      link.name = item.lbl.name
      link.id = item.rng

      this.links.push( link)
    })

    const fileClctn = this.item.msrs.filter((item) => (item.vid == ENV.MSR_FILE_ITEMS_ID))[0].clctn
    
    fileClctn.forEach( (item) => {
      let file = {};
      
      file.url = item.svl                        
      file.name = item.lbl.name
      file.uploaded = item.lbl.uploaded
      file.id = item.rng

      this.files.push( file)
    })

    const imageClctn = this.item.msrs.filter((item) => (item.vid == ENV.MSR_IMAGE_ITEMS_ID))[0].clctn
    
    imageClctn.forEach( (item) => {
      let image = {};
      
      image.url = item.svl                        
      image.name = item.lbl.name
      image.author = item.lbl.author
      image.uploaded = item.lbl.uploaded
      image.id = item.rng

      this.images.push( image)
    })  
  },

  computed: {

    getCountries () {
      const classes = this.item.countries.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },
    getClsfrs () {
      const classes = this.item.clsfrs.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },
    getChannelBackgroundColor () {

      let value = 1200000

      let bc = ``
      switch (value % 1200000) {
        case 0:
          bc = `background-color: ${'rgb(145,187,212)'}`
        break;

        case 1:
          bc = `background-color: ${'rgb(163,179,192)'}`
        break;
      
        case 2:
          bc =  `background-color: ${'rgb(153,149,166)'}`
        break;

        case 3:
          bc =  `background-color: ${'rgb(69,73,85)'}`
          //bc =  `background-color: ${'rgb(147,125,103)'}`
        break;

        case 4:
          bc =  `background-color: ${'rgb(96,124,148)'}`
        break;

        case 5:
          bc =  `background-color: ${'rgb(74,91,84)'}`
        break;

        case 6:
          bc =  `background-color: ${'rgb(194,181,164)'}`
        break;

        case 7:
          bc =  `background-color: ${'rgb(71,74,32)'}`
        break;

        case 8:
          bc =  `background-color: ${'rgb(69,73,85)'}`
        break;

        case 9:
          bc =  `background-color: ${'rgb(18,44,68)'}`
        break;

        case 10:
          bc =  `background-color: ${'rgb(97,64,61)'}`
        break;
      }

      return bc; 
    },

    isHideEntityControl() {
      return (this.msrsGroup.view == false)
    },

    getLinks () {
      return this.links
    },
    getFiles () {
      return this.files
    },
    getImages () {
      return this.images
    },
  },

  methods: {

    titleItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_KNOWLEDGE_NAME_ID);
    },
    annotationItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_KNOWLEDGE_ANNOTATION_ID);
    },
    multimediaItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_KNOWLEDGE_MULTIMEDIA_ID);
    },

    htmlItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_HTML_ID)
    },

    toggleHideEntityCotrol() {
      this.msrsGroup.view = !this.msrsGroup.view
    },
    filesCount () {
      return '(' + this.files.length + ')'
    },
    imagesCount () {
      return '(' + this.images.length + ')'
    },
    linksCount () {
      return '(' + this.links.length + ')'
    },

  },

}

</script>

<style lang="scss" scoped>
  @import '@/assets/knowledge-item-view.scss';

</style>
