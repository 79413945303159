<template>
  <div class="kn-preview-item">

    <div class="kn-knowledge-top" :style="getChannelBackgroundColor">
      <div class="kn-knowledge-classes">
        <span class="kn-knowledge-class">
          {{ getClsfrs }}
        </span>
        <span class="nw-news-class">
          , {{ getCountries }}
        </span>
      </div>
    </div>

    <AppLink :to="getLinkItem">

      <div class="kn-knowledge-preview">

        <div class="kn-knowledge-annot">
          <h2 v-if="titleItem().svl != ''" class="kn-knowledge-title" v-html="titleItem().svl" />
          <h2 v-else class="kn-knowledge-title">Link to article</h2>

          <div class="kn-knowledge-text" v-html="annotationItem().svl" />

        </div>

        <img v-if="multimediaItem().svl != ''" class="kn-knowledge-image" :src="multimediaItem().svl" />

      </div>

    </AppLink>

  </div>
</template>

<script>
import AppLink from '@/components/router/applink.vue'
import ENV from '@/costants/constants'

export default {
  name: 'KnowledgeListItemView',
  props: ['item'],
  components: {
    AppLink
  },
  data () {

    return
  },

  created () {
    //console.log('item: ', this.item);
  },

  computed: {

    getLinkItem () {
      return '/knowledge/' + this.item.id;
    },
    getCountries () {
      const classes = this.item.countries.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },
    getClsfrs () {
      const classes = this.item.clsfrs.nms
      let str = classes[0].nm
      for (let i = 1; i < classes.length; i++) {
        str = str + ', ' + classes[i].nm
      }
      return str
    },
    getChannelBackgroundColor () {

      let value = 20000000

      let bc = ``
      switch (value % 20000000) {
        case 0:
          bc = `background-color: ${'rgb(145,187,212)'}`
        break;

        case 1:
          bc = `background-color: ${'rgb(163,179,192)'}`
        break;
      
        case 2:
          bc =  `background-color: ${'rgb(153,149,166)'}`
        break;

        case 3:
          bc =  `background-color: ${'rgb(69,73,85)'}`
          //bc =  `background-color: ${'rgb(147,125,103)'}`
        break;

        case 4:
          bc =  `background-color: ${'rgb(96,124,148)'}`
        break;

        case 5:
          bc =  `background-color: ${'rgb(74,91,84)'}`
        break;

        case 6:
          bc =  `background-color: ${'rgb(194,181,164)'}`
        break;

        case 7:
          bc =  `background-color: ${'rgb(71,74,32)'}`
        break;

        case 8:
          bc =  `background-color: ${'rgb(69,73,85)'}`
        break;

        case 9:
          bc =  `background-color: ${'rgb(18,44,68)'}`
        break;

        case 10:
          bc =  `background-color: ${'rgb(97,64,61)'}`
        break;
      }

      return bc; 
    }

  },

  methods: {

    titleItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_KNOWLEDGE_NAME_ID);
    },
    annotationItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_KNOWLEDGE_ANNOTATION_ID);
    },
    multimediaItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_KNOWLEDGE_MULTIMEDIA_ID);
    },

  },

}
</script>

<style lang="scss" scoped>
  @import '@/assets/knowledge-list-view-item.scss';

</style>
