// import axios from 'axios'
import axios_http from '@/http/index.js'
import moment from 'moment'
import eventItem from '@/model/event-item.js'
import knowledgeItem from '@/model/knowledge-item.js'
import newsItem from '@/model/news-item.js'
import ENV from '@/costants/constants'

const d = new Date()
d.setDate(d.getDate() - 1)
const stringDate = d.toISOString().substring(0, 10)

const newsDate = new Date()
newsDate.setDate(newsDate.getDate() - 365)
const stringNewsDate = newsDate.toISOString().substring(0, 10)

const themesMap = new Map();
themesMap.set('event', {
  themeName: 'event',
  themeType: 3,
  isEdit: false,
  view: false,
  msrsGroup: {
    view: false,
    filesView: false,
    linksView: false
  },
  filter: {
    account: ENV.EVENT_ACCOUNT_FILTER,
    event: ENV.EVENTS_IDS_ITEM,
    time: {
      vl: moment(stringDate).format(),
      vle: moment('2122-12-31').format(),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    locality: ENV.EVENT_LOCALITY_FILTER,
    clsfrs: ENV.EVENT_CLS_FILTER,
    msrs: ENV.EVENT_MSRS,
  }

});
themesMap.set('knowledge', {
  themeName: 'knowledge',
  themeType: 2,
  isEdit: false,
  view: false,
  msrsGroup: {
    view: false,
    filesView: false,
    linksView: false,
    imagesView: false,
  },
  filter: {
    account: ENV.KNOWLEDGE_ACCOUNT_FILTER,
    event: ENV.EVENTS_IDS_ITEM,
    time: {
      vl: '1970-01-01T00:00:00+00',
      vle: '1970-01-01T00:00:00+00',
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    locality: ENV.KNOWLEDGE_LOCALITY_FILTER,
    clsfrs: ENV.KNOWLEDGE_CLS_FILTER,
    msrs: ENV.KNOWLEDGE_MSRS,
  }
});
themesMap.set('news', {
  themeName: 'news',
  themeType: 4,
  isEdit: false,
  view: false,
  msrsGroup: {
    view: false,
  },
  filter: {
    account: ENV.NEWS_ACCOUNT_FILTER,
    event: ENV.EVENTS_IDS_ITEM,
    time: {
      vl: moment(stringNewsDate).format(),
      vle: moment('2122-12-31').format(),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    locality: ENV.NEWS_LOCALITY_FILTER,
    clsfrs: ENV.NEWS_CLS_FILTER,
    msrs: ENV.NEWS_MSRS,
    src: ENV.NEWS_SRC_FILTER,
    thm: ENV.NEWS_THM_FILTER,
  }
});

//console.log('themesMap: ', themesMap);

const themesUploadMap = new Map();
themesUploadMap.set('event' , {
  themeName: 'event',
  themeType: 3,
  isEdit: true,
  view: true,
  msrsGroup: {
    view: false,
    filesView: false,
    linksView: false
  },
  texts: {
    view: true,
    shortView: true,
    longView: false
  },
  eventId: 0,
  filter: {
    account: ENV.NEWS_UPLOAD_ACCOUNT_FILTER,
    event: ENV.EVENTS_IDS_ITEM,
    time: {
      vl: moment(stringDate).format(),
      vle: moment('2122-12-31').format(),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    locality: ENV.EVENT_UPLOAD_LOCALITY_FILTER,
    clsfrs: ENV.EVENT_UPLOAD_CLS_FILTER,
    msrs: ENV.EVENT_UPLOAD_MSRS,
  }

});
themesUploadMap.set('knowledge', {
  themeName: 'knowledge',
  themeType: 2,
  isEdit: true,
  view: true,
  msrsGroup: {
    view: false,
    filesView: false,
    linksView: false,
    imagesView: false,
  },
  texts: {
    longView: true
  },
  knowledgeId: 0,
  filter: {
    account: ENV.KNOWLEDGE_UPLOAD_ACCOUNT_FILTER,
    event: ENV.EVENTS_IDS_ITEM,
    time: {
      vl: '1970-01-01T00:00:00+00',
      vle: '1970-01-01T00:00:00+00',
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    locality: ENV.KNOWLEDGE_UPLOAD_LOCALITY_FILTER,
    clsfrs: ENV.KNOWLEDGE_UPLOAD_CLS_FILTER,
    msrs: ENV.KNOWLEDGE_UPLOAD_MSRS,
  }
});
themesUploadMap.set('news', {
  themeName: 'news',
  themeType: 4,
  isEdit: false,
  view: true,
  msrsGroup: {
    view: false,
  },
  texts: {
    longView: true
  },
  filter: {
    account: ENV.NEWS_UPLOAD_ACCOUNT_FILTER,
    event: ENV.EVENTS_IDS_ITEM,
    time: {
      vl: moment(stringNewsDate).format(),
      vle: moment('2122-12-31').format(),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    locality: ENV.NEWS_UPLOAD_LOCALITY_FILTER,
    clsfrs: ENV.NEWS_UPLOAD_CLS_FILTER,
    msrs: ENV.NEWS_UPLOAD_MSRS,
    src: ENV.NEWS_UPLOAD_SRC_FILTER,
    thm: ENV.NEWS_UPLOAD_THM_FILTER,
  }
});

const initialEvent = [];
const cloneEventItem = JSON.parse(JSON.stringify( eventItem)) 
initialEvent.push(cloneEventItem);

const initialKnowledge = [];
const cloneKnowledgeItem = JSON.parse(JSON.stringify( knowledgeItem)) 
initialKnowledge.push(cloneKnowledgeItem);

const initialNews = [];
const cloneNewsItem = JSON.parse(JSON.stringify( newsItem)) 
initialNews.push(cloneNewsItem);

let state = new Map();

state.set('event', {
  event_themeOptions: themesMap.get('event'),
  eventUpload_themeOptions: themesUploadMap.get('event'),
  data: [],
  uploadData: initialEvent
});
state.set('knowledge', {
  knowledge_themeOptions: themesMap.get('knowledge'),
  knowledgeUpload_themeOptions: themesUploadMap.get('knowledge'),
  data: [],
  uploadData: initialKnowledge
});
state.set('news', {
  news_themeOptions: themesMap.get('news'),
  newsUpload_themeOptions: themesUploadMap.get('news'),
  data: [],
  uploadData: initialNews
});


const mutations = {
  UPDATE_THEME_ITEMS (state, payload) {
    state.get( payload.themeName).data = payload.data

    const knid = ENV.MSR_KNOWLEDGE_NAME_ID;

    if (state.get( payload.themeName).data) {
      switch (payload.themeType) {
        case 2:
          //console.log('state.get( payload.themeName).data: ', state.get( payload.themeName).data);
          //this.item.msrs.find( (itm) => itm.vid == 27);
          state.get( payload.themeName).data.sort((a,b) => {if (a.msrs.find( (itm) => itm.vid == knid).svl > b.msrs.find( (itm) => itm.vid == knid).svl) {return 1} if (a.msrs.find( (itm) => itm.vid == knid).svl < b.msrs.find( (itm) => itm.vid == knid).svl) {return -1} return 0})
          break;

        case 4:
          state.get( payload.themeName).data.sort((a,b) => {if (a.time.vl < b.time.vl) {return 1} if (a.time.vl > b.time.vl) {return -1} return 0})
          break;
      
        default:
          break;
      }
    }

  },

  SET_INITIAL_UPLOAD_THEME_ITEM (state, payload) {
    switch (payload.themeType) {
      case 2: //kowledge
        state.get( payload.themeName).knowledgeUpload_themeOptions = themesUploadMap.get('knowledge')
        state.get( payload.themeName).uploadData = []
        state.get( payload.themeName).uploadData.push(JSON.parse(JSON.stringify( knowledgeItem)))
        //  console.log('state.get( payload.themeName).uploadData: ', state.get( payload.themeName).uploadData)
      break;
      case 3: //event
        state.get( payload.themeName).eventUpload_themeOptions = themesUploadMap.get('event')
        state.get( payload.themeName).uploadData = []
        state.get( payload.themeName).uploadData.push(JSON.parse(JSON.stringify( eventItem)))
        //  console.log('state.get( payload.themeName).uploadData: ', state.get( payload.themeName).uploadData)
      break;
      case 4: //news
        state.get( payload.themeName).newsUpload_themeOptions = themesUploadMap.get('news')
        state.get( payload.themeName).uploadData = []
        state.get( payload.themeName).uploadData.push(JSON.parse(JSON.stringify( newsItem)))
        //  console.log('state.get( payload.themeName).uploadData: ', state.get( payload.themeName).uploadData)
      break;
    
    }
    state.get( payload.themeName).data = payload.data
  },

  UPDATE_THEME_ITEM (state, payload) {
    state.get( payload.themeName).uploadData[0] = payload.data[0]
  },

}

const actions = {
  async getThemeItems ({ commit }, payload) {
    switch (payload.themeType) {
      case 2: //knowledge
        //console.log('payload.filter: ', payload.filter)
        await axios_http
          .post('/knowledgedata/filter', payload.filter)
          .then((response) => {
            let result = {}
            result.data = response.data
            result.themeName = payload.themeName
            result.themeType = payload.themeType

            commit('UPDATE_THEME_ITEMS', result)
            //console.log('UPDATE_THEME_ITEMS result:', result)
          })
          .catch((error) => {
            console.log('GET knowledgeThemeItems data call unsuccessful :(', error)
          })
        
      break;
      case 3: //event
        await axios_http
          .post('/eventdata/filter', payload.filter)
          .then((response) => {
            let result = {}
            if (response.data === "") {
              result.data = []
            } else {
              result.data = response.data
            }
            result.themeName = payload.themeName
            result.themeType = payload.themeType

            commit('UPDATE_THEME_ITEMS', result)
            //console.log('UPDATE_THEME_ITEMS result:', result)
          })
          .catch((error) => {
            console.log('GET eventThemeItems data call unsuccessful :(', error)
          })
        
      break;
      case 4: //news
        await axios_http
          .post('/newsdata/filter', payload.filter)
          .then((response) => {
            let result = {}
            result.data = response.data
            result.themeName = payload.themeName
            result.themeType = payload.themeType

            commit('UPDATE_THEME_ITEMS', result)
            //console.log('UPDATE_THEME_ITEMS result:', result)
          })
          .catch((error) => {
            console.log('GET newsThemeItems data call unsuccessful :(', error)
          })
        
      break;
    
    }
  },

  getInitalUploadThemeItem ({ commit }, payload) {

    commit('SET_INITIAL_UPLOAD_THEME_ITEM', payload)

  },

  async uploadThemeItem ({ commit }, payload) {

    switch ( payload.themeType) {
      case 2: //knowledge
        await axios_http
          .post('/knowledgedata/upload', payload)
          .then((response) => {
            let result = {}
            result.data = response.data
            result.themeName = payload.themeName

            commit('UPDATE_THEME_ITEM', result)
          })
          .catch((error) => {
            console.log('POST upload knowledge data call unsuccessful :(', error)
          })
        
      break;
      case 3: //event
        await axios_http
          .post('/eventdata/upload', payload)
          .then((response) => {
            let result = {}
            result.data = response.data
            result.themeName = payload.themeName

            commit('UPDATE_THEME_ITEM', result)
          })
          .catch((error) => {
            console.log('POST upload events data call unsuccessful :(', error)
          })
        
      break;
      case 4: //news
        await axios_http
          .post('/newsdata/upload', payload)
          .then((response) => {
            let result = {}
            result.data = response.data
            result.themeName = payload.themeName

            commit('UPDATE_THEME_ITEM', result)
          })
          .catch((error) => {
            console.log('POST upload news data call unsuccessful :(', error)
          })
        
      break;

    }

  },

  async getThemeItem ({ commit }, payload) {

    switch (payload.themeType) {
      case 2: //knowledge
        await axios_http
          .post('/knowledgedata/get', payload)
          .then((response) => {
            let result = {}
            result.data = response.data
            result.themeName = payload.themeName

            commit('UPDATE_THEME_ITEM', result)
            //console.log('UPDATE_THEME_ITEM result:', result)
          })
          .catch((error) => {
            console.log('GET knowledgeThemeItem data call unsuccessful :(', error)
          })
        
      break;
      case 3: //event
        await axios_http
          .post('/eventdata/get', payload)
          .then((response) => {
            let result = {}
            result.data = response.data
            result.themeName = payload.themeName

            commit('UPDATE_THEME_ITEM', result)
            //console.log('UPDATE_THEME_ITEM result:', result)
          })
          .catch((error) => {
            console.log('GET eventThemeItem data call unsuccessful :(', error)
          })
        
      break;
      case 4: //news
        await axios_http
          .post('/newsdata/get', payload)
          .then((response) => {
            let result = {}
            result.data = response.data
            result.themeName = payload.themeName

            commit('UPDATE_THEME_ITEM', result)
            //console.log('UPDATE_THEME_ITEM result:', result)
          })
          .catch((error) => {
            console.log('GET newsThemeItem data call unsuccessful :(', error)
          })
        
      break;
    
    }
  },

}

const getters = {
  themes: state => state
}

const themesModule = {
  state,
  mutations,
  actions,
  getters
}

export default themesModule
