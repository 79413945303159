<template>

  {{ channelScriptLoad() }}

  <!--Edit-->
  <div v-if="auth.interfaces != 0 && !isEdit" class="nw-button-edit" @click="toggleIsEdit">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
      <polygon points="14 2 18 6 7 17 3 17 3 13 14 2"></polygon>
      <line x1="3" y1="22" x2="21" y2="22"></line>
    </svg>
  </div>
  <!--Close-->
  <div v-if="auth.interfaces != 0 && isEdit" class="nw-button-close" @click="toggleIsEdit">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="15" y1="9" x2="9" y2="15"></line>
      <line x1="9" y1="9" x2="15" y2="15"></line>
    </svg>
  </div>
  
  <div class="nw-item-page" v-if="renderViewItem">

    <div v-if="isEdit" class="nw-item-edit-control" id="stickyParentElement">

      <div class="nw-item-nav" v-if="isEdit">
        <div v-if="isHideControl()" class="nw-button-page-control" @click="toggleHideControl()" title="Show filter">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_4)">
            <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="white" stroke-opacity="1" stroke-width="2"/>
            <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
            </g>
            <defs>
            <clipPath id="clip0_1_4">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
        <div v-else class="nw-button-page-control" @click="toggleHideControl()" title="Hide filter">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_4)">
            <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
            <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="white" stroke-opacity="1" stroke-width="2"/>
            </g>
            <defs>
            <clipPath id="clip0_1_4">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        <div class="nw-options__value-contaier">
          <span><input v-model="classes.get('classNewsThemes').view" type="checkbox" name="countries" @change="changeViewNewsThemes()"/></span>
          <label class="checkbox-label" for="countries">Themes</label>
        </div>

        <div class="nw-options__value-contaier">
          <span><input v-model="classes.get('classNewsSources').view" type="checkbox" name="countries" @change="changeViewNewsSources()"/></span>
          <label class="checkbox-label" for="countries">Sources</label>
        </div>

        <div class="nw-options__value-contaier">
          <span><input v-model="classes.get('countries').view" type="checkbox" name="countries" @change="changeViewCountries()"/></span>
          <label class="checkbox-label" for="countries">Countries</label>
        </div>

        <div class="nw-options__value-contaier">
          <span><input v-model="classes.get('classHibiInfoItems').view" type="checkbox" name="countries" @change="changeViewClassHibiInfoItems()"/></span>
          <label class="checkbox-label" for="countries">Info</label>
        </div>

      </div>

      <div class="nw-news-control" v-if="!isHideControl()">
      
        <div class="nw-news-control__class" v-if="classView('classNewsThemes')">
          <Class :editMode="true" :classOptions="classesOptions.get('classNewsThemes').newsUpload_classOptions"/>
        </div>

        <div class="nw-news-control__class" v-if="classView('classNewsSources')">
          <Class :editMode="true" :classOptions="classesOptions.get('classNewsSources').newsUpload_classOptions"/>
        </div>

        <div class="nw-news-control__class" v-if="classView('countries')">
          <Class :editMode="true" :classOptions="classesOptions.get('countries').newsUpload_classOptions"/>
        </div>

        <div class="nw-news-control__class-long" v-if="classView('classHibiInfoItems')">
          <Class :editMode="true" :classOptions="classesOptions.get('classHibiInfoItems').newsUpload_classOptions"/>
        </div>

      </div>

      <div class="nw-item-editor-control">

        <div class="nw-item-editor-control-inline">
          <div class="nw-item-editor-control__value-contaier">
            <div class="nw-item-editor-control__label">
              Title:
            </div>
            <input type="text" v-model="titleItem().svl" class="nw-item-editor-control__input" required />
          </div>
        </div>

        <div class="nw-item-editor-control-inline">
          <div class="nw-item-editor-control__value-contaier">
            <div class="nw-item-editor-control__label">
              Annotation:
            </div>
            <textarea v-model="annotationItem().svl" :rows="5" class="nw-item-editor-control__input" required />
          </div>
        </div>

        <div class="nw-item-editor-control-inline">
          <div class="nw-item-editor-control__value-contaier">
            <div class="nw-item-editor-control__label">
              Multimedia:
            </div>
            <input type="text" v-model="multimediaItem().svl" class="nw-item-editor-control__input" required />
          </div>
        </div>

        <div class="nw-item-editor-control-inline">
          <div class="nw-item-editor-control__value-contaier">
            <div class="nw-item-editor-control__label">
              TimeZone country:
            </div>
            <Treeselect class="nw-item-editor-control__treeselect" 
              v-model="countriesValue" 
              :options="countriesOptions" 
              :clearable="true"
              :searchable="true"
              :disabled="false"
              :openOnClick="true"
              :openOnFocus="false"
              :closeOnSelect="true"
              :alwaysOpen="false"
              :appendToBody="false"
              :showCount="false"
              :disable-branch-nodes="true"

              :multiple="false"
              :flat="false" 

              :disableFuzzyMatching="false"
              :flattenSearchResults="true"
              :searchNested="false"

              placeholder="Select or(and) type item(s)..."
            />

          </div>
        </div>

        <div class="nw-item-editor-control-inline">
          <div class="nw-item-editor-control__value-contaier">
            <div class="nw-item-editor-control__label">
              TimeZone:
            </div>
            <Treeselect class="nw-item-editor-control__treeselect" v-if="renderTimeZones"
              v-model="zonesValue" 
              :options="zonesOptions" 
              :clearable="true"
              :searchable="true"
              :disabled="false"
              :openOnClick="true"
              :openOnFocus="false"
              :closeOnSelect="true"
              :alwaysOpen="false"
              :appendToBody="false"
              :showCount="false"
              :disable-branch-nodes="true"

              :multiple="false"
              :flat="false" 

              :disableFuzzyMatching="false"
              :flattenSearchResults="true"
              :searchNested="false"

              placeholder="Select or(and) type item(s)..."
            />

          </div>
        </div>

        <div class="nw-item-editor-control-inline">
          <div class="nw-item-editor-control__value-contaier">
            <div class="nw-item-editor-control__label">
              Time:
            </div>
            <Datepicker v-model="startTime" :is24="true" :utc="true" locale="ru" autoApply :closeOnAutoApply="false" textInput style="" required/>
          </div>
        </div>

      </div>

      <!--Save-->
      <div v-if="auth.interfaces != 0" class="nw-button-save" @click="preSaveNewsItem()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </div>

    </div>

    <div v-if="isEdit" class="nw-item-edit-text">

      <div v-show="themeOptions.texts.longView" class=".nw-item-editor">
        <Editor :modelValue="htmlItem()" :mode="mode" :allFunc="true" 
        :elId="newsId" :ownr="chld" :height="heightLongText" :prfx="prfx"
        />
      </div>

    </div>

    <div class="nw-item-view" v-if="item.id > 0 && renderViewItem">
      <ViewItem :item="item"/>
    </div>

  </div>

</template>

<script>
import { mapState } from 'vuex';
import emitter from '@/emitter.js';

import moment from 'moment-timezone';
import {countries, zones} from 'moment-timezone/data/meta/latest.json';

import Class from '@/components/classes/class.vue';
import Treeselect from '@/components/treeselect/Treeselect.vue';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import {style_html} from '@/components/editor/formatHtml.js';

import Editor from '@/components/editor/editor.vue';

import ViewItem from '@/components/news/NewsItemView.vue';
import ENV from '@/costants/constants';

export default {
  name: 'NewsItem',
  props: ['isInitialEdit', 'newsId'],
  components: {
    Class,
    Treeselect,
    Datepicker,
    Editor,
    ViewItem
  },
  data () {
    const classesMap = new Map();
    const countriesMap = new Map();

    const countriesArray = [];
    Object.values(countries).forEach(z => {
      countriesArray.push({ 'id': z.abbr, 'label': z.name })
      countriesMap.set(z.abbr, {
        zones: z.zones 
      })
    });

    const zonesArray = [];
    Object.values(zones).forEach(z => { 
      zonesArray.push({ 'id': z.name, 'label': z.name + ' ' + moment.tz(z.name).format('Z')})
    });

    return {
      renderComponent: false,
      renderTimeZones: true,
      renderViewItem: false,

      isEdit: false,

      newNews: this.newsId === 0,
      editNews: this.newsId > 0,
      classes: classesMap,
      themeOptions: {},
      filter: {},
      item: null,

      countriesOptions: countriesArray,
      countriesValue: null,
      countriesMap: countriesMap,
      zonesOptions: zonesArray,
      zonesValue: null, 
      startTime: '11:00',

      mode: 'edit',
      prfx: '',
      chld: {
        editor: null
      },

      heightLongText: 192,

    }
  },

  async created () {
    try {

      this.isEdit = this.isInitialEdit;

      this.classes.set('time', this.classesOptions.get('time').newsUpload_classOptions)
      this.classes.set('classNewsThemes', this.classesOptions.get('classNewsThemes').newsUpload_classOptions)
      this.classes.set('classNewsSources', this.classesOptions.get('classNewsSources').newsUpload_classOptions)
      this.classes.set('countries', this.classesOptions.get('countries').newsUpload_classOptions)
      this.classes.set('classHibiInfoItems', this.classesOptions.get('classHibiInfoItems').newsUpload_classOptions)

      this.themeOptions = this.themesOptions.get('news').newsUpload_themeOptions
      this.filter = this.themeOptions.filter

      if (this.newsId == 0) {
  
        this.getInitalUploadNewsItem ()


      } else {

        let payload = {}
        payload.newsId = this.newsId
        payload.themeType = this.themeOptions.themeType
        payload.themeName = this.themeOptions.themeName
        //console.log('payload: ', payload)

        await this.$store.dispatch('getThemeItem', payload)
      }

      this.item = this.themesOptions.get('news').uploadData[0]
      //console.log('loaded item this.item, this.filter: ', this.item, this.filter)
      
      if (this.newsId !== 0) {

        this.startTime = moment(this.item.time.vl).tz(this.item.time.tz).format()
        this.zonesValue = this.item.time.tz

        const countries = this.item.countries.ids.substring(1,this.item.countries.ids.length-1).split(',')
        const countriesFilter = this.classes.get('countries').filter
        countriesFilter.vl = []
        for (let i = 0; i < countries.length; i++) {
          if (countries[i] != 0) {
            countriesFilter.vl.push(Number(countries[i]))
          }
        }
        countriesFilter.options.multiple = true

        const clsarr = this.item.clsfrs.ids.substring(1,this.item.clsfrs.ids.length-1).split(',')

        let clsfrs = clsarr.filter(item => Number(item) < 1100000 )
        let clsfrsFilter = this.classes.get('classHibiInfoItems').filter
        clsfrsFilter.vl = []
        for (let i = 0; i < clsfrs.length; i++) {
          if (clsfrs[i] != 0) {
            clsfrsFilter.vl.push(Number(clsfrs[i]))
          }
        }
        clsfrsFilter.options.multiple = true

        clsfrs = clsarr.filter(item => Number(item) >= 1200000 && Number(item) < 1300000)
        clsfrsFilter = this.classes.get('classNewsThemes').filter
        clsfrsFilter.vl = []
        for (let i = 0; i < clsfrs.length; i++) {
          if (clsfrs[i] != 0) {
            clsfrsFilter.vl.push(Number(clsfrs[i]))
          }
        }
        clsfrsFilter.options.multiple = true

        clsfrs = clsarr.filter(item => Number(item) >= 1300000 && Number(item) < 1400000)
        clsfrsFilter = this.classes.get('classNewsSources').filter
        clsfrsFilter.vl = []
        for (let i = 0; i < clsfrs.length; i++) {
          if (clsfrs[i] != 0) {
            clsfrsFilter.vl.push(Number(clsfrs[i]))
          }
        }
        clsfrsFilter.options.multiple = true

      }

      for (let cls of this.classes.values()) {
        this.handleFilter (cls)
      }

      //console.log('loaded item this.item, this.filter: ', this.item, this.filter)

 //     this.renderComponent = true
      this.forceViewItemRerender();
      this.forceRerender()
      //console.log('this.item: ', this.item);

    } catch (error) {
      console.error('News upload created error:', error)
    } finally {

      for (let cls of this.classes.values()) {
        emitter.all.delete(cls.filter.event)
        emitter.on(cls.filter.event, () => {
          this.handleFilter (cls)
        })
      }

    }
  },

  watch: {
    countriesValue (newValue, oldValue) {
      let fake = oldValue
      oldValue = fake 

      this.updateTimeZone(newValue)
    }
  },
  
  updated () {
    if (window.instgrm) {
      window.instgrm.Embeds.process()
    }
  },

  computed: {
    ...mapState({
      auth: state => state.auth,
      classesOptions: state => state.classes2,
      themesOptions: state => state.themes2
    }),

    viewHeight () {
      let height
      if (this.themeOptions.view == true) {
        height = this.navHeight + 36
      } else {
        height = this.navHeight
      }

      this.setTopHeight(height)

      return 'calc(100vh - ' + height + 'px)'
    }

  },

  methods: {
    toggleIsEdit () {
      if (this.isEdit) {
        this.forceViewItemRerender()
      }
      this.isEdit = !this.isEdit
    },
    isHideControl() {
      if (!this.themeOptions.view) {
        return true
      } else {
        return false 
      }
    },
    toggleHideControl() {
      this.themeOptions.view = !this.themeOptions.view
    },
    changeViewNewsThemes() {
      //if (!this.classes.get('countries').view) {
        this.handleFilter(this.classes.get('classNewsThemes'))
      //}
    },
    changeViewNewsSources() {
      //if (!this.classes.get('countries').view) {
        this.handleFilter(this.classes.get('classNewsSources'))
      //}
    },
    changeViewClassHibiInfoItems() {
      //if (!this.classes.get('classHibiInfoItems').view) {
        this.handleFilter(this.classes.get('classHibiInfoItems'))
      //}
    },
    changeViewCountries() {
      //if (!this.classes.get('countries').view) {
        this.handleFilter(this.classes.get('countries'))
      //}
    },

    classView (className) {
      return this.classes.get(className).view
    },

    titleItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_NEWS_NAME_ID);
    },
    annotationItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_NEWS_ANNOTATION_ID);
    },
    multimediaItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_NEWS_MULTIMEDIA_ID);
    },
    htmlItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_NEWS_HTML_ID)
    },

    handleFilter (cls) {
      
      switch (cls.className) {

        case 'classNewsThemes':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.thm = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.thm = this.filter.thm + cls.filter.vl[i] + ','
                }
                this.filter.thm = this.filter.thm.substring(0, this.filter.thm.length-1)
              } else {
                this.filter.thm = cls.filter.vl.toString()
              }
              this.filter.thm = '{' + this.filter.thm + '}'
            } else {
              this.filter.thm = '{}'  
            }
          
          } else {
            this.filter.thm = '{0}'
          }

          //console.log('this.filter.clsfrs: ', this.filter.clsfrs)
        
        break;

        case 'classNewsSources':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.src = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.src = this.filter.src + cls.filter.vl[i] + ','
                }
                this.filter.src = this.filter.src.substring(0, this.filter.src.length-1)
              } else {
                this.filter.src = cls.filter.vl.toString()
              }
              this.filter.src = '{' + this.filter.src + '}'
            } else {
              this.filter.src = '{}'  
            }
          
          } else {
            this.filter.src = '{0}'
          }

          //console.log('this.filter.clsfrs: ', this.filter.clsfrs)
        
        break;

        case 'countries':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.locality = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.locality = this.filter.locality + cls.filter.vl[i] + ','
                }
                this.filter.locality = this.filter.locality.substring(0, this.filter.locality.length-1)
              } else {
                this.filter.locality = cls.filter.vl.toString()
              }
              this.filter.locality = '{' + this.filter.locality + '}'
            } else {
              this.filter.locality = '{}'  
            }
          
          } else {
            this.filter.locality = '{0}'
          }

          //console.log('this.filter.locality: ', this.filter.locality)
        
        break;

        case 'classHibiInfoItems':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.clsfrs = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.clsfrs = this.filter.clsfrs + cls.filter.vl[i] + ','
                }
                this.filter.clsfrs = this.filter.clsfrs.substring(0, this.filter.clsfrs.length-1)
              } else {
                this.filter.clsfrs = cls.filter.vl.toString()
              }
              this.filter.clsfrs = '{' + this.filter.clsfrs + '}'
            } else {
              this.filter.clsfrs = '{}'  
            }
          
          } else {
            this.filter.clsfrs = '{0}'
          }

          //console.log('this.filter.clsfrs: ', this.filter.clsfrs)
        
        break;
      }

      //console.log('handleFilter this.filter, cls: ', this.filter, cls)
    },

    outputHtml () {
      this.htmlItem().svl = style_html( this.chld.editor.getHTML(), {
        'indent_size': 2,
        'indent_char': ' ',
        'max_char': 78,
        'brace_style': 'expand',
        'unformatted': ['a', 'sub', 'sup', 'b', 'i', 'u', 's', 'span', 'pre', 'code']
      })
    },

    updateTimeZone(newValue) {
      this.zonesOptions = []
      if (newValue !== undefined) {
        const timeZones = this.countriesMap.get(newValue).zones
        for (let index = 0; index < timeZones.length; index++) {
          this.zonesOptions.push({ 'id': timeZones[index], 'label': timeZones[index] + ' ' + moment.tz(timeZones[index]).format('Z')})
          //const element = array[index];
          
        }

        this.zonesValue = this.zonesOptions[0].id

        //countries.get(newValue).zones

        //console.log('newValue: ', newValue, this.zonesValue, this.zonesOptions, this.countriesMap, timeZones)

        this.forceTimeZonesRerender()

      } else {
        Object.values(zones).forEach(z => { 
          this.zonesOptions.push({ 'id': z.name, 'label': z.name + ' ' + moment.tz(z.name).format('Z')})
        });
        //console.log('newValue1: ', newValue)

      }
    },

    getInitalUploadNewsItem () {
      this.$store.dispatch('getInitalUploadThemeItem', this.themeOptions)
    },

    preSaveNewsItem () {

      if (this.filter.event !== '{}' && this.filter.locality !== '{}' && this.filter.clsfrs !== '{}') {
        this.saveNewsItem ()
      } else {
        window.prompt('When a classifier is selected, at least one item must be selected. \n Please select a classifier item or deselect a classifier. ');
      }
    },

    async saveNewsItem () {
      try {
        this.item.time.vl = moment(this.startTime).format()
        this.item.time.vle = this.item.time.vl
        this.item.time.tz = this.zonesValue

        this.item.clsfrs.ids = '{0,' + this.filter.clsfrs.substring(1,this.filter.clsfrs.length-1)
          + ',' + this.filter.thm.substring(1,this.filter.thm.length-1)
          + ',' + this.filter.src.substring(1,this.filter.src.length-1)
          + '}'
        this.item.countries.ids = '{0,' + this.filter.locality.substring(1,this.filter.locality.length-1)
          + '}'

        this.outputHtml()

        let payload = {}
        payload.item = this.item
        payload.filter = this.filter
        payload.themeType = this.themeOptions.themeType
        payload.themeName = this.themeOptions.themeName

        //console.log('before uploadThemeItem payload: ', payload)

        await this.$store.dispatch('uploadThemeItem', payload)

        this.item = this.themesOptions.get('news').uploadData[0]
        
        //console.log('after uploadThemeItem this.item: ', this.item)

        //emitter.emit('newsComponentFilter')

        //this.forceRerender()

      } catch (error) {
        console.err('saveNewsItem error:', error)
      }
    },


    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },

    forceViewItemRerender() {
      // Remove my-component from the DOM
      this.renderViewItem = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderViewItem = true
      })
    },
    channelScriptLoad () {
      if (document.getElementById('inst-widgets') == null) {
        const embed2 = document.createElement('script')
        embed2.id = 'inst-widgets'
        embed2.src = '//www.instagram.com/embed.js'
        embed2.setAttribute('defer', 'defer');
        document.body.appendChild(embed2)
      }

      if (document.getElementById('tlgrm-widgets')) {
        const elem = document.getElementById('tlgrm-widgets')
        elem.parentNode.removeChild(elem)
      }
      const embed = document.createElement('script')
      embed.id = 'tlgrm-widgets'
      embed.src = 'https://telegram.org/js/telegram-widget.js?19'
      embed.setAttribute('defer', 'defer');
      document.body.appendChild(embed)

      if (document.getElementById('twitter-widgets')) {
        const elem = document.getElementById('twitter-widgets')
        elem.parentNode.removeChild(elem)
      }
      const embed3 = document.createElement('script')
      embed3.id = 'twitter-widgets'
      embed3.src = 'https://platform.twitter.com/widgets.js'
      embed3.setAttribute('defer', 'defer');
      document.body.appendChild(embed3)

    },

  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/news-item.scss';
  .sentinel {
    height: 0px;
  }

</style>
