<template>

  {{ channelScriptLoad() }}

  <!--Edit-->
  <div v-if="auth.interfaces != 0 && !isEdit" class="kn-button-edit" @click="toggleIsEdit">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
      <polygon points="14 2 18 6 7 17 3 17 3 13 14 2"></polygon>
      <line x1="3" y1="22" x2="21" y2="22"></line>
    </svg>
  </div>
  <!--Close-->
  <div v-if="auth.interfaces != 0 && isEdit" class="kn-button-close" @click="toggleIsEdit">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="15" y1="9" x2="9" y2="15"></line>
      <line x1="9" y1="9" x2="15" y2="15"></line>
    </svg>
  </div>
  
  <div class="kn-item-page" v-if="renderComponent">

    <div v-if="isEdit" class="kn-item-edit-control" id="stickyParentElement">

      <div class="kn-item-nav" v-if="isEdit">
        <div v-if="isHideControl()" class="kn-button-page-control" @click="toggleHideControl()" title="Show filter">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_4)">
            <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="white" stroke-opacity="1" stroke-width="2"/>
            <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
            </g>
            <defs>
            <clipPath id="clip0_1_4">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
        <div v-else class="kn-button-page-control" @click="toggleHideControl()" title="Hide filter">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_4)">
            <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
            <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="white" stroke-opacity="1" stroke-width="2"/>
            </g>
            <defs>
            <clipPath id="clip0_1_4">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        <div class="kn-options__value-contaier">
          <span><input v-model="classes.get('classHibiInfoItems').view" type="checkbox" name="countries" @change="changeViewClassHibiInfoItems()"/></span>
          <label class="checkbox-label" for="countries">Info</label>
        </div>

        <div class="kn-options__value-contaier">
          <span><input v-model="classes.get('countries').view" type="checkbox" name="countries" @change="changeViewCountries()"/></span>
          <label class="checkbox-label" for="countries">Countries</label>
        </div>

      </div>

      <div class="kn-knowledge-control" v-if="!isHideControl()">
      
        <div class="kn-knowledge-control__class-long" v-if="classView('classHibiInfoItems')">
          <Class :editMode="true" :classOptions="classesOptions.get('classHibiInfoItems').knowledgeUpload_classOptions"/>
        </div>

        <div class="kn-knowledge-control__class" v-if="classView('countries')">
          <Class :editMode="true" :classOptions="classesOptions.get('countries').knowledgeUpload_classOptions"/>
        </div>

      </div>

      <div class="kn-item-editor-control">

        <div class="kn-item-editor-control-inline">
          <div class="kn-item-editor-control__value-contaier">
            <div class="kn-item-editor-control__label">
              Title:
            </div>
            <input type="text" v-model="titleItem().svl" class="kn-item-editor-control__input" required />
          </div>
        </div>

        <div class="kn-item-editor-control-inline">
          <div class="kn-item-editor-control__value-contaier">
            <div class="kn-item-editor-control__label">
              Annotation:
            </div>
            <textarea v-model="annotationItem().svl" :rows="5" class="kn-item-editor-control__input" required />
          </div>
        </div>

        <div class="kn-item-editor-control-inline">
          <div class="kn-item-editor-control__value-contaier">
            <div class="kn-item-editor-control__label">
              Multimedia:
            </div>
            <input type="text" v-model="multimediaItem().svl" class="kn-item-editor-control__input" required />
          </div>
        </div>

        <div class="kn-item-editor-control-inline kn1-msrs-group-select-container">
          <div class="kn-msrs-group-select-container">
            <div v-if="isHideMsrsGroupControl()" class="kn-button-page-control" @click="toggleMsrsGroupControl()" title="Show filter">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_4)">
                <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="white" stroke-opacity="1" stroke-width="2"/>
                <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
                </g>
                <defs>
                <clipPath id="clip0_1_4">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <div v-else class="kn-button-page-control" @click="toggleMsrsGroupControl()" title="Hide filter">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_4)">
                <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
                <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="white" stroke-opacity="1" stroke-width="2"/>
                </g>
                <defs>
                <clipPath id="clip0_1_4">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>

            <div class="kn-options__value-contaier">
              <span><input v-model="themeOptions.msrsGroup.filesView" type="checkbox" name="filesView"/></span>
              <label class="checkbox-label" for="filesView">Files {{ filesCount() }}</label>
            </div>

            <div class="kn-options__value-contaier">
              <span><input v-model="themeOptions.msrsGroup.imagesView" type="checkbox" name="imagesView"/></span>
              <label class="checkbox-label" for="imagesView">Images {{ imagesCount() }}</label>
            </div>

            <div class="kn-options__value-contaier">
              <span><input v-model="themeOptions.msrsGroup.linksView" type="checkbox" name="linksView"/></span>
              <label class="checkbox-label" for="linksView">Links {{ linksCount() }}</label>
            </div>
          </div>


        </div>



      </div>

      <div v-if="!isHideMsrsGroupControl()" class="kn-msrs-group-items">
        <FilesCellGroup v-if="themeOptions.msrsGroup.filesView" :files="getFiles()" :elId="knowledgeId" :prfx="prfx"/>
        <ImagesCellGroup v-if="themeOptions.msrsGroup.imagesView" :images="getImages()" :elId="knowledgeId" :prfx="prfx"/>
        <LinksCellGroup v-if="themeOptions.msrsGroup.linksView" :links="getLinks()" :elId="knowldgeId"/>
      </div>

      <!--Save-->
      <div v-if="auth.interfaces != 0" class="kn-button-save" @click="preSaveKnowledgeItem()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </div>

    </div>


    <div v-if="isEdit" class="kn-item-edit-text">

      <div v-show="themeOptions.texts.longView" class=".kn-item-editor">
        <Editor :modelValue="htmlItem()" :mode="mode" :allFunc="true" 
        :elId="knowledgeId" :ownr="chld" :height="heightLongText" :prfx="prfx"
        />
      </div>

    </div>

    <div class="kn-item-view" v-if="item.id > 0 && renderViewItem">
      <ViewItem :item="item"/>
    </div>

  </div>

</template>

<script>
import { mapState } from 'vuex';
import emitter from '@/emitter.js';

//import moment from 'moment-timezone';

import Class from '@/components/classes/class.vue';
import 'vue3-date-time-picker/dist/main.css';
import {style_html} from '@/components/editor/formatHtml.js';

import LinksCellGroup from '@/components/links/links-cell-group.vue'
import FilesCellGroup from '@/components/files/files-cell-group.vue'
import ImagesCellGroup from '@/components/images/images-cell-group.vue'

import Editor from '@/components/editor/editor.vue';

import ViewItem from '@/components/knowledge/KnowledgeItemView.vue';
import ENV from '@/costants/constants';

export default {
  name: 'KnowledgeItem',
  props: ['isInitialEdit', 'knowledgeId'],
  components: {
    Class,
    LinksCellGroup,
    FilesCellGroup,
    ImagesCellGroup,
    Editor,
    ViewItem
  },
  data () {
    const classesMap = new Map();

    return {
      renderComponent: false,
      renderViewItem: false,

      isEdit: false,

      newKnowledge: this.knowledgeId === 0,
      editKnowledge: this.knowledgeId > 0,
      classes: classesMap,
      themeOptions: {},
      filter: {},
      item: null,

      mode: 'edit',
      prfx: '',
      chld: {
        editor: null
      },

      heightLongText: 192,

      links: [],
      files: [],
      images: [],

    }
  },

  async created () {
    try {

      //console.log('knowledgeId: ', this.knowledgeId);

      this.isEdit = this.isInitialEdit;

      this.classes.set('countries', this.classesOptions.get('countries').knowledgeUpload_classOptions);
      this.classes.get('countries').view = true;
      this.classes.set('classHibiInfoItems', this.classesOptions.get('classHibiInfoItems').knowledgeUpload_classOptions);
      this.classes.get('classHibiInfoItems').view = true;

      this.themeOptions = this.themesOptions.get('knowledge').knowledgeUpload_themeOptions
      this.filter = this.themeOptions.filter

      if (this.knowledgeId == 0) {
  
        this.getInitalUploadKnowledgeItem ()

      } else {

        let payload = {}
        payload.knowledgeId = this.knowledgeId
        payload.themeType = this.themeOptions.themeType
        payload.themeName = this.themeOptions.themeName
        //console.log('payload: ', payload)

        await this.$store.dispatch('getThemeItem', payload)
      }

      this.item = this.themesOptions.get('knowledge').uploadData[0]
      //console.log('loaded item this.item, this.filter: ', this.item, this.filter)

      const linkClctn = this.item.msrs.filter((item) => (item.vid == ENV.MSR_LINK_ITEMS_ID))[0].clctn
    
      linkClctn.forEach( (item) => {
        let link = {};
        
        link.url = item.svl                        
        link.name = item.lbl.name
        link.id = item.rng

        this.links.push( link)
      })

      const fileClctn = this.item.msrs.filter((item) => (item.vid == ENV.MSR_FILE_ITEMS_ID))[0].clctn
      
      fileClctn.forEach( (item) => {
        let file = {};
        
        file.url = item.svl                        
        file.name = item.lbl.name
        file.uploaded = item.lbl.uploaded
        file.id = item.rng

        this.files.push( file)
      })

      const imageClctn = this.item.msrs.filter((item) => (item.vid == ENV.MSR_IMAGE_ITEMS_ID))[0].clctn
      
      imageClctn.forEach( (item) => {
        let image = {};
        
        image.url = item.svl                        
        image.name = item.lbl.name
        image.author = item.lbl.author
        image.uploaded = item.lbl.uploaded
        
        image.uploaded = true
        
        image.id = item.rng

        //console.log('image: ', image)

        this.images.push( image)
      })  

      
      if (this.knowledgeId !== 0) {

        const countries = this.item.countries.ids.substring(1,this.item.countries.ids.length-1).split(',')
        const countriesFilter = this.classes.get('countries').filter
        countriesFilter.vl = []
        for (let i = 0; i < countries.length; i++) {
          if (countries[i] != 0) {
            countriesFilter.vl.push(Number(countries[i]))
          }
        }
        countriesFilter.options.multiple = true

        const clsarr = this.item.clsfrs.ids.substring(1,this.item.clsfrs.ids.length-1).split(',')

        let clsfrs = clsarr.filter(item => Number(item) < 11000000 )
        let clsfrsFilter = this.classes.get('classHibiInfoItems').filter
        clsfrsFilter.vl = []
        for (let i = 0; i < clsfrs.length; i++) {
          if (clsfrs[i] != 0) {
            clsfrsFilter.vl.push(Number(clsfrs[i]))
          }
        }
        clsfrsFilter.options.multiple = true

      }

      for (let cls of this.classes.values()) {
        this.handleFilter (cls)
      }

      //console.log('loaded item this.item, this.filter: ', this.item, this.filter)

 //     this.renderComponent = true
      this.forceRerender()
      this.forceViewItemRerender();
      //console.log('this.item: ', this.item);

    } catch (error) {
      console.error('News upload created error:', error)
    } finally {
      emitter.all.delete('applay-link-item')
      emitter.on('applay-link-item', (event) => this.applayLinks(event.newLinks))

      emitter.all.delete('add-link-item')
      emitter.on('add-link-item', (event) => this.addLink(event.newLink))

      emitter.all.delete('applay-file-item')
      emitter.on('applay-file-item', (event) => this.applayFiles(event.newFiles))

      emitter.all.delete('add-file-item')
      emitter.on('add-file-item', (event) => this.addFile(event.newFile))

      emitter.all.delete('applay-image-item')
      emitter.on('applay-image-item', (event) => this.applayImages(event.newImages))

      emitter.all.delete('add-image-item')
      emitter.on('add-image-item', (event) => this.addImage(event.newImage))

      for (let cls of this.classes.values()) {
        emitter.all.delete(cls.filter.event)
        emitter.on(cls.filter.event, () => {
          this.handleFilter (cls)
        })
      }

    }
  },

  updated () {
    if (window.instgrm) {
      window.instgrm.Embeds.process()
    }
  },

  computed: {
    ...mapState({
      auth: state => state.auth,
      classesOptions: state => state.classes2,
      themesOptions: state => state.themes2
    }),

  },

  methods: {
    toggleIsEdit () {
      if (this.isEdit) {
        this.forceViewItemRerender()
      }
      this.isEdit = !this.isEdit
    },
    isHideControl() {
      if (!this.themeOptions.view) {
        return true
      } else {
        return false 
      }
    },
    toggleHideControl() {
      this.themeOptions.view = !this.themeOptions.view
    },
    changeViewClassHibiInfoItems() {
      //if (!this.classes.get('classHibiInfoItems').view) {
        this.handleFilter(this.classes.get('classHibiInfoItems'))
      //}
    },
    changeViewCountries() {
      //if (!this.classes.get('countries').view) {
        this.handleFilter(this.classes.get('countries'))
      //}
    },

    isHideMsrsGroupControl() {
      if (!this.themeOptions.msrsGroup.view) {
        return true
      } else {
        return false 
      }
    },
    toggleMsrsGroupControl () {
      this.themeOptions.msrsGroup.view = !this.themeOptions.msrsGroup.view
    },

    classView (className) {
      return this.classes.get(className).view
    },

    titleItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_KNOWLEDGE_NAME_ID);
    },
    annotationItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_KNOWLEDGE_ANNOTATION_ID);
    },
    multimediaItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_KNOWLEDGE_MULTIMEDIA_ID);
    },
    htmlItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_HTML_ID)
    },

    getFiles () {
      return this.files
    },
    getImages () {
      return this.images
    },
    getLinks () {
      return this.links
    },
    filesCount () {
      return '(' + this.files.length + ')'
    },
    imagesCount () {
      return '(' + this.images.length + ')'
    },
    linksCount () {
      return '(' + this.links.length + ')'
    },

    applayLinks (newLinks) {
      //push newImages to images
      let index = this.links.length  
      newLinks.forEach( (item) => {
        let link = {};
        link.isSave = true
        link.isNew = item.isNew  //true
        link.file = item.file
        link.url = item.url                        
        link.name = item.name
        link.uploaded = item.uploaded
        link.checked = false;
        link.id = index

        index = index+1
        this.links.push( link)
      })
    },
    addLink (newLink) {
      //push newLinks to links
      const index = this.links.length  

      let link = {};
      link.isSave = true
      link.isNew = true  //true
      link.file = null
      link.url = newLink.url                        
      link.name = newLink.name
      link.uploaded = false
      link.checked = false;
      link.id = index

      this.links.push( link)
    },
    applayFiles (newFiles) {
      //push newImages to images
      let index = this.files.length  
      newFiles.forEach( (item) => {
        let file = {};
        file.isSave = true
        file.isNew = item.isNew  //true
        file.file = item.file
        file.url = item.url                        
        file.name = item.name
        file.uploaded = item.uploaded
        file.checked = false;
        file.id = index

        index = index+1
        this.files.push( file)
      })
    },
    addFile (newFile) {
      //push newImages to images
      const index = this.files.length  

      let file = {};
      file.isSave = true
      file.isNew = true  //true
      file.file = null
      file.url = newFile.url                        
      file.name = newFile.name
      file.uploaded = false
      file.checked = false;
      file.id = index

      this.files.push( file)
    },
    applayImages (newImages) {
      //push newImages to images
      let index = this.images.length  
      newImages.forEach( (item) => {
        let image = {};
        image.isSave = true
        image.isNew = item.isNew  //true
        image.file = item.file
        image.url = item.url                        
        image.name = item.name
        image.author = item.author
        image.uploaded = item.uploaded
        image.checked = false;
        image.id = index

        index = index+1
        this.images.push( image)
      })
    },
    addImage (newImage) {
      //push newImages to images
      const index = this.images.length  

      let image = {};
      image.isSave = true
      image.isNew = true  //true
      image.file = null
      image.url = newImage.url                        
      image.name = newImage.name
      image.author = newImage.author
      image.uploaded = false
      image.checked = false;
      image.id = index

      this.images.push( image)
    },


    handleFilter (cls) {
      
      switch (cls.className) {

        case 'countries':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.locality = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.locality = this.filter.locality + cls.filter.vl[i] + ','
                }
                this.filter.locality = this.filter.locality.substring(0, this.filter.locality.length-1)
              } else {
                this.filter.locality = cls.filter.vl.toString()
              }
              this.filter.locality = '{' + this.filter.locality + '}'
            } else {
              this.filter.locality = '{}'  
            }
          
          } else {
            this.filter.locality = '{0}'
          }

          //console.log('this.filter.locality: ', this.filter.locality)
        
        break;

        case 'classHibiInfoItems':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.clsfrs = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.clsfrs = this.filter.clsfrs + cls.filter.vl[i] + ','
                }
                this.filter.clsfrs = this.filter.clsfrs.substring(0, this.filter.clsfrs.length-1)
              } else {
                this.filter.clsfrs = cls.filter.vl.toString()
              }
              this.filter.clsfrs = '{' + this.filter.clsfrs + '}'
            } else {
              this.filter.clsfrs = '{}'  
            }
          
          } else {
            this.filter.clsfrs = '{0}'
          }

          //console.log('this.filter.clsfrs: ', this.filter.clsfrs)
        
        break;
      }

      //console.log('handleFilter this.filter, cls: ', this.filter, cls)
    },

    outputHtml () {
      this.htmlItem().svl = style_html( this.chld.editor.getHTML(), {
        'indent_size': 2,
        'indent_char': ' ',
        'max_char': 78,
        'brace_style': 'expand',
        'unformatted': ['a', 'sub', 'sup', 'b', 'i', 'u', 's', 'span', 'pre', 'code']
      })
    },

    getInitalUploadKnowledgeItem () {
      this.$store.dispatch('getInitalUploadThemeItem', this.themeOptions)
    },

    preSaveKnowledgeItem () {

      if (this.filter.event !== '{}' && this.filter.locality !== '{}' && this.filter.clsfrs !== '{}') {
        this.saveKnowledgeItem ()
      } else {
        window.prompt('When a classifier is selected, at least one item must be selected. \n Please select a classifier item or deselect a classifier. ');
      }
    },

    async saveKnowledgeItem () {
      try {
        //this.item.time.vl = moment(this.startTime).format()
        //this.item.time.vle = this.item.time.vl
        //this.item.time.tz = this.zonesValue

        this.item.time.tz = this.filter.time.tz

        this.item.msrs.filter((item) => (item.vid == ENV.MSR_LINK_ITEMS_ID))[0].clctn = []
        for (let i=0; i < this.links.length ; i++) {
          let link = {}

          link.tp = 4
          link.vl = -7777777
          link.lbl = {name: this.links[i].name}
          link.rng = this.links[i].id
          link.svl = this.links[i].url
          link.isDlt = false

          this.item.msrs.filter((item) => (item.vid == ENV.MSR_LINK_ITEMS_ID))[0].clctn.push( link )
        }

        this.item.msrs.filter((item) => (item.vid == ENV.MSR_FILE_ITEMS_ID))[0].clctn = []
        for (let i=0; i < this.files.length ; i++) {
          let file = {}

          file.tp = 8
          file.vl = -7777777
          file.lbl = {name: this.files[i].name, uploaded: this.files[i].uploaded}
          file.rng = this.files[i].id
          file.svl = this.files[i].url
          file.isDlt = false

          this.item.msrs.filter((item) => (item.vid == ENV.MSR_FILE_ITEMS_ID))[0].clctn.push( file )
        }

        this.item.msrs.filter((item) => (item.vid == ENV.MSR_IMAGE_ITEMS_ID))[0].clctn = []
        for (let i=0; i < this.images.length ; i++) {
          let image = {}

          image.tp = 32
          image.vl = -7777777
          image.lbl = {name: this.images[i].name, author: this.images[i].author, uploaded: this.images[i].uploaded}
          image.rng = this.images[i].id
          image.svl = this.images[i].url
          image.isDlt = false

          this.item.msrs.filter((item) => (item.vid == ENV.MSR_IMAGE_ITEMS_ID))[0].clctn.push( image )
        }

        this.item.clsfrs.ids = '{0,' + this.filter.clsfrs.substring(1,this.filter.clsfrs.length-1)
          + '}'
        this.item.countries.ids = '{0,' + this.filter.locality.substring(1,this.filter.locality.length-1)
          + '}'

        this.outputHtml()

        let payload = {}
        payload.item = this.item
        payload.filter = this.filter
        payload.themeType = this.themeOptions.themeType
        payload.themeName = this.themeOptions.themeName
        //console.log('Item to Save: ', this.item);

        //console.log('before uploadThemeItem payload: ', payload)

        await this.$store.dispatch('uploadThemeItem', payload)

        this.item = this.themesOptions.get('knowledge').uploadData[0]

        //emitter.emit('updateEditor', {})
        
        //console.log('after uploadThemeItem this.item: ', this.item)

        //this.forceRerender()
        //this.forceViewItemRerender()


      } catch (error) {
        console.error('saveKnowledgeItem error:', error)
      }
    },


    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },

    forceViewItemRerender() {
      // Remove my-component from the DOM
      this.renderViewItem = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderViewItem = true
      })
    },
    channelScriptLoad () {
      if (document.getElementById('inst-widgets') == null) {
        const embed2 = document.createElement('script')
        embed2.id = 'inst-widgets'
        embed2.src = '//www.instagram.com/embed.js'
        embed2.setAttribute('defer', 'defer');
        document.body.appendChild(embed2)
      }

      if (document.getElementById('tlgrm-widgets')) {
        const elem = document.getElementById('tlgrm-widgets')
        elem.parentNode.removeChild(elem)
      }
      const embed = document.createElement('script')
      embed.id = 'tlgrm-widgets'
      embed.src = 'https://telegram.org/js/telegram-widget.js?19'
      embed.setAttribute('defer', 'defer');
      document.body.appendChild(embed)

      if (document.getElementById('twitter-widgets')) {
        const elem = document.getElementById('twitter-widgets')
        elem.parentNode.removeChild(elem)
      }
      const embed3 = document.createElement('script')
      embed3.id = 'twitter-widgets'
      embed3.src = 'https://platform.twitter.com/widgets.js'
      embed3.setAttribute('defer', 'defer');
      document.body.appendChild(embed3)

    },

  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/knowledge-item.scss';
  .sentinel {
    height: 0px;
  }
  
</style>
