//require('dotenv').config()

const ENV = {
    LOCALITY_ID: process.env.VUE_APP_LOCALITY_ID,
    LOCALITY_ID_NAME: process.env.VUE_APP_LOCALITY_ID_NAME,
    COUNTRIES_IDS_ITEM: process.env.VUE_APP_COUNTRIES_IDS_ITEM,
    ACCOUNT_ID: process.env.VUE_APP_ACCOUNT_ID | 0,
    EVENT_ID: process.env.VUE_APP_EVENT_ID | 0,
    EVENT_ID_NAME: process.env.VUE_APP_EVENT_ID_NAME,
    EVENTS_IDS_ITEM: process.env.VUE_APP_EVENTS_IDS_ITEM,
    EVENT_MSRS: process.env.VUE_APP_EVENT_MSRS,
    EVENT_CLS_ID: process.env.VUE_APP_EVENT_CLS_ID | 0,
    EVENT_CLS: process.env.VUE_APP_EVENT_CLS,
    KNOWLEDGE_MSRS: process.env.VUE_APP_KNOWLEDGE_MSRS,
    KNOWLEDGE_CLS: process.env.VUE_APP_KNOWLEDGE_CLS,
    NEWS_MSRS: process.env.VUE_APP_NEWS_MSRS,
    NEWS_CLS: process.env.VUE_APP_NEWS_CLS,
    
    EVENT_LOCALITY_FILTER: process.env.VUE_APP_EVENT_LOCALITY_FILTER,
    EVENT_UPLOAD_LOCALITY_FILTER: process.env.VUE_APP_EVENT_UPLOAD_LOCALITY_FILTER,
    EVENT_ACCOUNT_FILTER: process.env.VUE_APP_EVENT_ACCOUNT_FILTER | 0,
    EVENT_UPLOAD_ACCOUNT_FILTER: process.env.VUE_APP_EVENT_UPLOAD_ACCOUNT_FILTER | 0,
    EVENT_UPLOAD_MSRS: process.env.VUE_APP_EVENT_UPLOAD_MSRS,
    EVENT_CLS_FILTER: process.env.VUE_APP_EVENT_CLS_FILTER,
    EVENT_UPLOAD_CLS_FILTER: process.env.VUE_APP_EVENT_UPLOAD_CLS_FILTER,

    KNOWLEDGE_LOCALITY_FILTER: process.env.VUE_APP_KNOWLEDGE_LOCALITY_FILTER,
    KNOWLEDGE_UPLOAD_LOCALITY_FILTER: process.env.VUE_APP_KNOWLEDGE_UPLOAD_LOCALITY_FILTER,
    KNOWLEDGE_ACCOUNT_FILTER: process.env.VUE_APP_KNOWLEDGE_ACCOUNT_FILTER | 0,
    KNOWLEDGE_UPLOAD_ACCOUNT_FILTER: process.env.VUE_APP_KNOWLEDGE_UPLOAD_ACCOUNT_FILTER | 0,
    KNOWLEDGE_UPLOAD_MSRS: process.env.VUE_APP_KNOWLEDGE_UPLOAD_MSRS,
    KNOWLEDGE_CLS_FILTER: process.env.VUE_APP_KNOWLEDGE_CLS_FILTER,
    KNOWLEDGE_UPLOAD_CLS_FILTER: process.env.VUE_APP_KNOWLEDGE_UPLOAD_CLS_FILTER,

    NEWS_LOCALITY_FILTER: process.env.VUE_APP_NEWS_LOCALITY_FILTER,
    NEWS_UPLOAD_LOCALITY_FILTER: process.env.VUE_APP_NEWS_LOCALITY_FILTER,
    NEWS_ACCOUNT_FILTER: process.env.VUE_APP_NEWS_ACCOUNT_FILTER | 0,
    NEWS_UPLOAD_ACCOUNT_FILTER: process.env.VUE_APP_NEWS_UPLOAD_ACCOUNT_FILTER | 0,
    NEWS_UPLOAD_MSRS: process.env.VUE_APP_NEWS_UPLOAD_MSRS,
    NEWS_CLS_FILTER: process.env.VUE_APP_NEWS_CLS_FILTER,
    NEWS_UPLOAD_CLS_FILTER: process.env.VUE_APP_NEWS_UPLOAD_CLS_FILTER,
    NEWS_SRC_FILTER: process.env.VUE_APP_NEWS_SRC_FILTER,
    NEWS_UPLOAD_SRC_FILTER: process.env.VUE_APP_NEWS_UPLOAD_SRC_FILTER,
    NEWS_THM_FILTER: process.env.VUE_APP_NEWS_THM_FILTER,
    NEWS_UPLOAD_THM_FILTER: process.env.VUE_APP_NEWS_UPLOAD_THM_FILTER,
    
    MSR_HTML_ID: process.env.VUE_APP_MSR_HTML_ID | 0,
    MSR_LINK_ITEMS_ID: process.env.VUE_APP_MSR_LINK_ITEMS_ID | 0,
    MSR_FILE_ITEMS_ID: process.env.VUE_APP_MSR_FILE_ITEMS_ID | 0,
    MSR_IMAGE_ITEMS_ID: process.env.VUE_APP_MSR_IMAGE_ITEMS_ID |0,
    MSR_ISIMPORTANT_ID: process.env.VUE_APP_MSR_ISIMPORTANT_ID | 0,

    MSR_EVENT_NAME_ID: process.env.VUE_APP_MSR_EVENT_NAME_ID | 0,
    MSR_EVENT_SHORT_HTML_ID: process.env.VUE_APP_MSR_EVENT_SHORT_HTML_ID | 0,
    MSR_EVENT_HTML_ID: process.env.VUE_APP_MSR_EVENT_HTML_ID | 0,

    MSR_NEWS_HTML_ID: process.env.VUE_APP_MSR_NEWS_HTML_ID | 0,
    MSR_NEWS_NAME_ID: process.env.VUE_APP_MSR_NEWS_NAME_ID | 0,
    MSR_NEWS_MULTIMEDIA_ID: process.env.VUE_APP_MSR_NEWS_MULTIMEDIA_ID | 0,
    MSR_NEWS_ANNOTATION_ID: process.env.VUE_APP_MSR_NEWS_ANNOTATION_ID | 0,
    
    MSR_KNOWLEDGE_NAME_ID: process.env.VUE_APP_MSR_KNOWLEDGE_NAME_ID | 0,
    MSR_KNOWLEDGE_ANNOTATION_ID: process.env.VUE_APP_MSR_KNOWLEDGE_ANNOTATION_ID | 0,
    MSR_KNOWLEDGE_MULTIMEDIA_ID: process.env.VUE_APP_MSR_KNOWLEDGE_MULTIMEDIA_ID | 0,

    CLS_INFO_ID: process.env.VUE_APP_CLS_INFO_ID | 0,
    CLS_NEWS_THEMES_ID: process.env.VUE_APP_CLS_NEWS_THEMES_ID | 0,
    CLS_NEWS_SOURCES_ID: process.env.VUE_APP_CLS_NEWS_SOURCES_ID | 0,
}

//console.log('ENV: ', ENV);

export default ENV;
