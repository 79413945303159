import ENV from "@/costants/constants"

// News object
const NewsItem = {
  "id": 0,
  "account": ENV.ACCOUNT_ID,
  "event": {
    "id": ENV.EVENT_ID,
    "nm": ENV.EVENT_ID_NAME
  },
  "events": {
    "ids": ENV.EVENTS_IDS_ITEM,
    "nms": [
      {
        "id": ENV.EVENT_ID,
        "nm": ENV.EVENT_ID_NAME
      }
    ]
  },
  "time": {
    "vl": "1970-01-01T00:00:00+00",
    "vle": "1970-01-01T00:00:00+00"
  },
  "locality": {
    "id": ENV.LOCALITY_ID,
    "dtl": {
        "vl": "",
        "gps": "",
        "region": ""
    }
  },
  "countries": {
    "ids": ENV.COUNTRIES_IDS_ITEM,
    "nms": [
      {
        "id": ENV.LOCALITY_ID,
        "nm": ENV.LOCALITY_ID_NAME
      }
    ]
  },
  "clsfrs": {
    "ids": ENV.NEWS_CLS,
    "nms": []
  },
  "vid": 217,
  "tp": 0,
  "vl": -7777777,
  "svl": "",
  "icln": true,
  "igrp": true,
  "ich": false,
  "inw": true,
  "isDlt": false,
  "msrs": [
    {
      "vid": 235,
      "nm": "HI-BI General Values News HTML",
      "tp": 64,
      "lbl": "",
      "vl": -7777777,
      "svl": '',
      "icln": false,
      "ich": false,
      "inw": true,
      "isDlt": false
    },
    {
      "vid": 236,
      "nm": "HI-BI General Values News Name",
      "tp": 64,
      "lbl": "",
      "vl": -7777777,
      "svl": '',
      "icln": false,
      "ich": false,
      "inw": true,
      "isDlt": false
    },
    {
      "vid": 237,
      "nm": "HI-BI General Values News SEO",
      "tp": 64,
      "lbl": "",
      "vl": -7777777,
      "svl": '',
      "icln": false,
      "ich": false,
      "inw": true,
      "isDlt": false
    },
    {
      "vid": 238,
      "nm": "HI-BI General Values News Author",
      "tp": 64,
      "lbl": "",
      "vl": -7777777,
      "svl": '',
      "icln": false,
      "ich": false,
      "inw": true,
      "isDlt": false
    },
    {
      "vid": 239,
      "nm": "HI-BI General Values News Multimedia",
      "tp": 64,
      "lbl": "",
      "vl": -7777777,
      "svl": '',
      "icln": false,
      "ich": false,
      "inw": true,
      "isDlt": false
    },
    {
      "vid": 240,
      "nm": "HI-BI General Values News Annotation",
      "tp": 64,
      "lbl": "",
      "vl": -7777777,
      "svl": '',
      "icln": false,
      "ich": false,
      "inw": true,
      "isDlt": false
    }
  ]
}

export default NewsItem;
