<template>
  <div class="ev-nav" v-if="render">
    <div v-if="isHideControl()" class="ev-button-page-control" @click="toggleHideControl()" title="Show filter">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_4)">
        <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="white" stroke-opacity="1" stroke-width="2"/>
        <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
        </g>
        <defs>
        <clipPath id="clip0_1_4">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </div>
    <div v-else class="ev-button-page-control" @click="toggleHideControl()" title="Hide filter">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_4)">
        <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
        <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="white" stroke-opacity="1" stroke-width="2"/>
        </g>
        <defs>
        <clipPath id="clip0_1_4">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </div>

    <div class="ev-options__value-contaier">
      <span><input v-model="classes.get('eventTypes').view" type="checkbox" name="eventType" @change="changeViewEventTypes()"/></span>
      <label class="checkbox-label" for="eventType">Event</label>
    </div>

    <div class="ev-options__value-contaier">
      <span><input v-model="classes.get('countries').view" type="checkbox" name="countries" @change="changeViewCountries()"/></span>
      <label class="checkbox-label" for="countries">Countries</label>
    </div>

    <div class="ev-options__value-contaier">
      <span><input v-model="classes.get('classHibiInfoItems').view" type="checkbox" name="classHibiInfoItems" @change="changeViewClassHibiInfoItems()"/></span>
      <label class="checkbox-label" for="classHibiInfoItems">Info</label>
    </div>
  
  </div>

  <div class="ev-page-main__container" v-if="render">
    <div class="ev-page-main__wrapper">

      <div class="evup-page-main__view" v-if="(newEvent || editEvent) && renderComponent">
        <div class="ev-calendar-control" v-if="!isHideControl()">
          
          <div class="ev-calendar-control__class" v-if="classView('eventTypes')">
            <Class :editMode="true" :classOptions="this.classesOptions.get('eventTypes').eventUpload_classOptions"/>
          </div>

          <div class="ev-calendar-control__class" v-if="classView('countries')">
            <Class :editMode="true" :classOptions="classesOptions.get('countries').eventUpload_classOptions"/>
          </div>

          <div class="ev-calendar-control__class-long" v-if="classView('classHibiInfoItems')">
            <Class :editMode="true" :classOptions="this.classesOptions.get('classHibiInfoItems').eventUpload_classOptions"/>
          </div>

        </div>

        <div class="evup-article-upload">
          
          <div class="evup-control-inline">
            <div class="evup-options__value-contaier">
              <div class="evup-options__label">
                Name:
              </div>
              <input v-model="eventName().svl" type="text" id="eventName" placeholder="Enter an event name ..." />
            </div>
          </div>
          
          <div class="evup-control-inline">
            <div class="evup-options__value-contaier">
              <label for="scales">Important:</label> 
              <span>
                <input v-model="imprtnt" type="checkbox" id="scales" name="isImprtnt" checked />
              </span>
            </div>
            <div class="evup-options__value-contaier">
              <div class="evup-options__label">
                TimeZone country:
              </div>
              <Treeselect class="evup-treeselect" 
                v-model="countriesValue" 
                :options="countriesOptions" 
                :clearable="true"
                :searchable="true"
                :disabled="false"
                :openOnClick="true"
                :openOnFocus="false"
                :closeOnSelect="true"
                :alwaysOpen="false"
                :appendToBody="false"
                :showCount="false"
                :disable-branch-nodes="true"

                :multiple="false"
                :flat="false" 

                :disableFuzzyMatching="false"
                :flattenSearchResults="true"
                :searchNested="false"

                placeholder="Select or(and) type item(s)..."
              />

            </div>
          </div>

          <div class="evup-control-inline">
            <div class="evup-options__value-contaier">
              <div class="evup-options__label">
                TimeZone:
              </div>
              <Treeselect class="evup-treeselect" v-if="renderTimeZones"
                v-model="zonesValue" 
                :options="zonesOptions" 
                :clearable="true"
                :searchable="true"
                :disabled="false"
                :openOnClick="true"
                :openOnFocus="false"
                :closeOnSelect="true"
                :alwaysOpen="false"
                :appendToBody="false"
                :showCount="false"
                :disable-branch-nodes="true"

                :multiple="false"
                :flat="false" 

                :disableFuzzyMatching="false"
                :flattenSearchResults="true"
                :searchNested="false"

                placeholder="Select or(and) type item(s)..."
              />

            </div>
          </div>
        
          <div class="evup-control-inline">
            <div class="evup-options__value-contaier">
              <div class="evup-options__label">
                Start:
              </div>
              <Datepicker v-model="startTime" :is24="true" locale="ru" autoApply :closeOnAutoApply="false" textInput style="" required/>
            </div>
            <div class="evup-options__value-contaier">
              <div class="evup-options__label">
                End:
              </div>
              <Datepicker v-model="endTime" time-picker :is24="true" locale="ru" autoApply :closeOnAutoApply="false" textInput style="" required/>
            </div>
            <div class="evup-options__value-contaier">
              <label for="notime">No time:</label> 
              <span>
                <input v-model="noTime" type="checkbox" id="notime" name="noTime" @change="changeNoTime()" />
              </span>
            </div>
            <div class="evup-options__value-contaier">
              <label for="sameendtime">Same end time:</label> 
              <span>
                <input v-model="sameEndTime" type="checkbox" id="sameendtime" name="sameEndTime" @change="changeSameEndTime()" />
              </span>
            </div>
          </div>
          
          <div class="evup-control-inline">
            <div class="evup-options__locality-contaier">
              <div  class="evup-options__locality-name">
                <label>Locality name:</label>
                <div>
                  <input v-model="item.locality.dtl.vl"  type="text" placeholder="Enter a name ..." />
                </div>
              </div>
              <div class="evup-options__locality-detail">
                <div class="evup-options__locality-region">
                  <label>region:</label>
                  <div>
                    <input v-model="item.locality.dtl.region" type="text" placeholder="Enter a region ..."  />
                  </div>
                </div>
                <div class="evup-options__locality-gps">
                  <label>gps:</label>
                  <div>
                    <input v-model="item.locality.dtl.gps" type="text" placeholder="Enter a gps ..."  />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="evup-group-select-container">
            <div class="ev-nav">
              <div v-if="isHideMsrsGroupControl()" class="ev-button-page-control" @click="toggleMsrsGroupControl()" title="Show filter">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1_4)">
                  <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="white" stroke-opacity="1" stroke-width="2"/>
                  <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1_4">
                  <rect width="16" height="16" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
              <div v-else class="ev-button-page-control" @click="toggleMsrsGroupControl()" title="Hide filter">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1_4)">
                  <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
                  <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="white" stroke-opacity="1" stroke-width="2"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1_4">
                  <rect width="16" height="16" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>

              <div class="ev-options__value-contaier">
                <span><input v-model="themeOptions.msrsGroup.filesView" type="checkbox" name="eventType"/></span>
                <label class="checkbox-label" for="eventType">Files {{ filesCount() }}</label>
              </div>

              <div class="ev-options__value-contaier">
                <span><input v-model="themeOptions.msrsGroup.linksView" type="checkbox" name="countries"/></span>
                <label class="checkbox-label" for="countries">Links {{ linksCount() }}</label>
              </div>
            </div>

            <div v-if="!isHideMsrsGroupControl()" class="hb-entity-select-items">
              <FilesCellGroup v-if="themeOptions.msrsGroup.filesView" :files="getFiles()" :elId="eventId" :prfx="prfx"/>
              <LinksCellGroup v-if="themeOptions.msrsGroup.linksView" :links="getLinks()" :elId="eventId"/>
            </div>

          </div>

          <div class="evup-group-select-container">
            <div class="ev-nav">
              <div v-if="isHideTextsControl()" class="ev-button-page-control" @click="toggleTextsControl()" title="Show filter">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1_4)">
                  <line x1="11.364" y1="8.70711" x2="4.29293" y2="1.63604" stroke="white" stroke-opacity="1" stroke-width="2"/>
                  <line x1="4.29295" y1="14.3639" x2="11.364" y2="7.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1_4">
                  <rect width="16" height="16" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
              <div v-else class="ev-button-page-control" @click="toggleTextsControl()" title="Hide filter">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1_4)">
                  <line x1="7.29295" y1="11.3639" x2="14.364" y2="4.29287" stroke="white" stroke-opacity="1" stroke-width="2"/>
                  <line x1="1.63612" y1="4.29289" x2="8.70719" y2="11.364" stroke="white" stroke-opacity="1" stroke-width="2"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1_4">
                  <rect width="16" height="16" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>

              <div class="ev-options__value-contaier">
                <span><input v-model="themeOptions.texts.shortView" type="checkbox" name="shortText"/></span>
                <label class="checkbox-label" for="shortText">Short text</label>
              </div>

              <div class="ev-options__value-contaier">
                <span><input v-model="themeOptions.texts.longView" type="checkbox" name="longText"/></span>
                <label class="checkbox-label" for="longText">Text</label>
              </div>
            </div>

            <div v-show="!isHideTextsControl()">

              <div v-show="themeOptions.texts.shortView" class="evup-editor">
                <Editor :modelValue="shortHtmlItem()" :mode="mode" :allFunc="false" :elId="eventId" :ownr="chldShortText" :height="heightShortText"/>
              </div>

              <div v-show="themeOptions.texts.longView" class="evup-editor">
                <Editor :modelValue="htmlItem()" :mode="mode" :allFunc="true" :elId="eventId" :ownr="chldLongText" :height="heightLongText" :prfx="prfx"/>
              </div>

            </div>
            
            <!--Save-->
            <div class="evup-button-save" :disabled="isSaving" @click="preSaveEventItem" title="Save">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <!--View-->
            <div v-if="editEvent" class="evup-button-view" :disabled="isSaving" @click="viewEventItem" title="View mode">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import Class from '@/components/classes/class.vue'
import moment from 'moment-timezone'
import {countries, zones} from 'moment-timezone/data/meta/latest.json'
import emitter from '@/emitter.js'

import Treeselect from '@/components/treeselect/Treeselect.vue'
import LinksCellGroup from '@/components/links/links-cell-group.vue'
import FilesCellGroup from '@/components/files/files-cell-group.vue'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import Editor from '@/components/editor/editor.vue'
import {style_html} from '@/components/editor/formatHtml.js'
import ENV from '@/costants/constants'

export default {
  name: 'EventUpload',
  props: ['eventId'],
  components: {
    Class,
    Treeselect,
    LinksCellGroup, FilesCellGroup, 
    Datepicker,
    Editor
  },
  data() {
    const classesMap = new Map();
    const countriesMap = new Map();

    const countriesArray = [];
    Object.values(countries).forEach(z => {
      countriesArray.push({ 'id': z.abbr, 'label': z.name })
      countriesMap.set(z.abbr, {
        zones: z.zones 
      })
    });

    const zonesArray = [];
    Object.values(zones).forEach(z => { 
      zonesArray.push({ 'id': z.name, 'label': z.name + ' ' + moment.tz(z.name).format('Z')})
    });

    return {
      render: false,
      renderComponent: true,
      renderTimeZones: true,
      isSaving: false,
      prfx: 'e',
      newEvent: this.eventId === 0,
      editEvent: this.eventId > 0,
      classes: classesMap,
      countriesOptions: countriesArray,
      countriesValue: null,
      countriesMap: countriesMap,
      zonesOptions: zonesArray,
      zonesValue: null, 

      themeOptions: {},
      filter: {},
      item: null,
      links: [],
      files: [],

      startTime: '11:00',
      endTime: {hours: 12, minutes: 0},
      noTime: false,
      sameEndTime: false,
      imprtnt: false,
      
      mode: 'edit',
      chldShortText: {
        editor: null
      },
      chldLongText: {
        editor: null
      },
      heightShortText: 592,
      heightLongText: 288,
    }
  },

  watch: {
    countriesValue (newValue, oldValue) {
      let fake = oldValue
      oldValue = fake 

      this.updateTimeZone(newValue)
    }
  },

  computed: {
    ...mapState({
      classesOptions: state => state.classes2,
      themesOptions: state => state.themes2
    }),

  },

  async created () {
    try {

      this.classes.set('time', this.classesOptions.get('time').eventUpload_classOptions)
      this.classes.set('eventTypes', this.classesOptions.get('eventTypes').eventUpload_classOptions)
      this.classes.set('countries', this.classesOptions.get('countries').eventUpload_classOptions)
      this.classes.set('classHibiInfoItems', this.classesOptions.get('classHibiInfoItems').eventUpload_classOptions)

      this.themeOptions = this.themesOptions.get('event').eventUpload_themeOptions

      if (this.eventId == 0) {
  
        this.getInitalUploadEventItem ()
  
      } else {

        let payload = {}
        payload.eventId = this.eventId
        payload.themeType = this.themeOptions.themeType
        payload.themeName = this.themeOptions.themeName

        await this.$store.dispatch('getThemeItem', payload)
      }

      this.item = this.themesOptions.get('event').uploadData[0]

      if (this.eventId == 0) {

        this.filter = this.themeOptions.filter

        this.zonesValue = this.filter.time.tz

        let d = new Date()
        d.setUTCHours(11)
        this.startTime = moment.tz(d.toISOString().substring(0, 19), this.zonesValue).format()
        //d.setUTCHours(12)
        //this.endTime = moment.tz(d.toISOString().substring(0, 19), this.zonesValue).format()

      } else {

        this.zonesValue = this.item.time.tz

        let time
        time = this.item.time
        time.vl = moment.tz(time.vl, time.tz).format()
        time.vle = moment.tz(time.vle, time.tz).format()

        this.startTime = time.vl.substring(0, 19)
        this.endTime.hours = Number( time.vle.substring(11,13))
        this.endTime.minutes = Number( time.vle.substring(14,16))

        const events = this.item.events.ids.substring(1,this.item.events.ids.length-1).split(',')
        const eventsFilter = this.classes.get('eventTypes').filter
        eventsFilter.vl = []
        for (let i = 1; i < events.length; i++) {
          eventsFilter.vl.push(Number(events[i]))
        }
        eventsFilter.options.multiple = true

        const countries = this.item.countries.ids.substring(1,this.item.countries.ids.length-1).split(',')
        const countriesFilter = this.classes.get('countries').filter
        countriesFilter.vl = []
        for (let i = 1; i < countries.length; i++) {
          countriesFilter.vl.push(Number(countries[i]))
        }
        countriesFilter.options.multiple = true

        const clsfrs = this.item.clsfrs.ids.substring(1,this.item.clsfrs.ids.length-1).split(',')
        const clsfrsFilter = this.classes.get('classHibiInfoItems').filter
        clsfrsFilter.vl = []
        for (let i = 1; i < clsfrs.length; i++) {
          clsfrsFilter.vl.push(Number(clsfrs[i]))
        }
        clsfrsFilter.options.multiple = true

      }

      for (let cls of this.classes.values()) {
        this.handleFilter (cls)
      }

      if (this.getImprtnt().vl == 1) {
        this.imprtnt = true  
      } else {
        this.imprtnt = false
      }

      const linkClctn = this.item.msrs.filter((item) => (item.vid == ENV.MSR_LINK_ITEMS_ID))[0].clctn
      linkClctn.forEach( (item) => {
        let link = {};
        
        link.isSave = true
        link.isNew = false
        link.file = null
        link.url = item.svl                        
        link.name = item.lbl.name
        link.uploaded = item.lbl.uploaded
        link.checked = false;
        link.id = item.rng

        this.links.push( link)
      })

      const fileClctn = this.item.msrs.filter((item) => (item.vid == ENV.MSR_FILE_ITEMS_ID))[0].clctn
      fileClctn.forEach( (item) => {
        let file = {};
        
        file.isSave = true
        file.isNew = false
        file.file = null
        file.url = item.svl                        
        file.name = item.lbl.name
        file.uploaded = item.lbl.uploaded
        file.checked = false;
        file.id = item.rng

        this.files.push( file)
      })

      //console.log('created this.item, this.filter: ', this.item, this.filter)
      this.render = true

    } catch (error) {
      console.error('created eventsItem upload error:', error)
    } finally {
      emitter.all.delete('applay-file-item')
      emitter.on('applay-file-item', (event) => this.applayFiles(event.newFiles))

      emitter.all.delete('add-file-item')
      emitter.on('add-file-item', (event) => this.addFile(event.newFile))

      emitter.all.delete('applay-link-item')
      emitter.on('applay-link-item', (event) => this.applayLinks(event.newLinks))

      emitter.all.delete('add-link-item')
      emitter.on('add-link-item', (event) => this.addLink(event.newLink))

      for (let cls of this.classes.values()) {
        emitter.all.delete(cls.filter.event)
        emitter.on(cls.filter.event, () => {
          this.handleFilter (cls)
        })
      }

      //this.forceRerender()
    }
  },
  methods: {
    isHideControl() {
      if (!this.themeOptions.view) {
        return true
      } else {
        return false 
      }
    },
    toggleHideControl () {
      this.themeOptions.view = !this.themeOptions.view
    },
    changeViewEventTypes() {
      //if (!this.classes.get('eventTypes').view) {
        this.handleFilter(this.classes.get('eventTypes'))
      //}
    },
    changeViewCountries() {
      //if (!this.classes.get('countries').view) {
        this.handleFilter(this.classes.get('countries'))
      //}
    },
    changeViewClassHibiInfoItems() {
      //if (!this.classes.get('classHibiInfoItems').view) {
        this.handleFilter(this.classes.get('classHibiInfoItems'))
      //}
    },

    changeNoTime() {
      if (this.noTime) {
        let vl = moment(this.startTime).format()
        vl = vl.substring(0,10) + "T00:00:00"
        this.startTime = vl;

        this.endTime.hours = 0
        this.endTime.minutes = 0

      }
    },
    changeSameEndTime() {
      if (this.sameEndTime) {

        const vle = moment(this.startTime).format()

        this.endTime.hours = Number( vle.substring(11,13))
        this.endTime.minutes = Number( vle.substring(14,16))

      }
    },

    isHideMsrsGroupControl() {
      if (!this.themeOptions.msrsGroup.view) {
        return true
      } else {
        return false 
      }
    },
    toggleMsrsGroupControl () {
      this.themeOptions.msrsGroup.view = !this.themeOptions.msrsGroup.view
    },

    applayFiles (newFiles) {
      //push newImages to images
      let index = this.files.length  
      newFiles.forEach( (item) => {
        let file = {};
        file.isSave = true
        file.isNew = item.isNew  //true
        file.file = item.file
        file.url = item.url                        
        file.name = item.name
        file.uploaded = item.uploaded
        file.checked = false;
        file.id = index

        index = index+1
        this.files.push( file)
      })
    },
    addFile (newFile) {
      //push newImages to images
      const index = this.files.length  

      let file = {};
      file.isSave = true
      file.isNew = true  //true
      file.file = null
      file.url = newFile.url                        
      file.name = newFile.name
      file.uploaded = false
      file.checked = false;
      file.id = index

      this.files.push( file)
    },
    applayLinks (newLinks) {
      //push newImages to images
      let index = this.links.length  
      newLinks.forEach( (item) => {
        let link = {};
        link.isSave = true
        link.isNew = item.isNew  //true
        link.file = item.file
        link.url = item.url                        
        link.name = item.name
        link.uploaded = item.uploaded
        link.checked = false;
        link.id = index

        index = index+1
        this.links.push( link)
      })
    },
    addLink (newLink) {
      //push newLinks to links
      const index = this.links.length  

      let link = {};
      link.isSave = true
      link.isNew = true  //true
      link.file = null
      link.url = newLink.url                        
      link.name = newLink.name
      link.uploaded = false
      link.checked = false;
      link.id = index

      this.links.push( link)
    },

    isHideTextsControl() {
      if (!this.themeOptions.texts.view) {
        return true
      } else {
        return false 
      }
    },
    toggleTextsControl () {
      this.themeOptions.texts.view = !this.themeOptions.texts.view
    },

    classView (className) {
      return this.classes.get(className).view
    },

    eventName () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_EVENT_NAME_ID)
    },
    shortHtmlItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_EVENT_SHORT_HTML_ID)
    },
    htmlItem () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_EVENT_HTML_ID)
    },
    getFiles () {
      return this.files
    },
    getLinks () {
      return this.links
    },
    getImprtnt () {
      return this.item.msrs.find( (itm) => itm.vid == ENV.MSR_ISIMPORTANT_ID)
    },
    filesCount () {
      return '(' + this.files.length + ')'
    },
    linksCount () {
      return '(' + this.links.length + ')'
    },

    outputHtml () {
      this.shortHtmlItem().svl = style_html( this.chldShortText.editor.getHTML(), {
        'indent_size': 2,
        'indent_char': ' ',
        'max_char': 78,
        'brace_style': 'expand',
        'unformatted': ['a', 'sub', 'sup', 'b', 'i', 'u', 's', 'span', 'pre', 'code']
      })
      this.htmlItem().svl = style_html( this.chldLongText.editor.getHTML(), {
        'indent_size': 2,
        'indent_char': ' ',
        'max_char': 78,
        'brace_style': 'expand',
        'unformatted': ['a', 'sub', 'sup', 'b', 'i', 'u', 's', 'span', 'pre', 'code']
      })
    },

    handleFilter (cls) {

      switch (cls.className) {

        case 'eventTypes':
        if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.event = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.event = this.filter.event + cls.filter.vl[i] + ','
                }
                this.filter.event = this.filter.event.substring(0, this.filter.event.length-1)
              } else {
                this.filter.event = cls.filter.vl.toString()
              }
              this.filter.event = '{' + this.filter.event + '}'
            } else {
              this.filter.event = '{}'  
            }
          
          } else {
            this.filter.event = '{0}'
          }
          
          //console.log('this.filter.event, this.item.events: ', this.filter.event, this.item.event, this.item.events)
        
        break;

        case 'countries':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.locality = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.locality = this.filter.locality + cls.filter.vl[i] + ','
                }
                this.filter.locality = this.filter.locality.substring(0, this.filter.locality.length-1)
              } else {
                this.filter.locality = cls.filter.vl.toString()
              }
              this.filter.locality = '{' + this.filter.locality + '}'
            } else {
              this.filter.locality = '{}'  
            }
          
          } else {
            this.filter.locality = '{0}'
          }

          //console.log('this.filter.locality, this.item.countries: ', this.filter.locality, this.item.locality, this.item.countries)
        
        break;

        case 'classHibiInfoItems':
          if (cls.view) {
            if (cls.filter.vl !== null && cls.filter.vl !== undefined) {

              this.filter.clsfrs = ''
              if ( Array.isArray(cls.filter.vl) ) {
                for (let i = 0; i < cls.filter.vl.length; i++) {
                  this.filter.clsfrs = this.filter.clsfrs + cls.filter.vl[i] + ','
                }
                this.filter.clsfrs = this.filter.clsfrs.substring(0, this.filter.clsfrs.length-1)
              } else {
                this.filter.clsfrs = cls.filter.vl.toString()
              }
              this.filter.clsfrs = '{' + this.filter.clsfrs + '}'
            } else {
              this.filter.clsfrs = '{}'  
            }
          
          } else {
            this.filter.clsfrs = '{0}'
          }

          //console.log('this.filter.clsfrs, this.item.clsfrs: ', this.filter.clsfrs, this.item.clsfrs)
        
        break;
      }

      //console.log('handleUploadFilter: ', cls)
    },

    updateTimeZone(newValue) {
      this.zonesOptions = []
      if (newValue !== undefined) {
        const timeZones = this.countriesMap.get(newValue).zones
        for (let index = 0; index < timeZones.length; index++) {
          this.zonesOptions.push({ 'id': timeZones[index], 'label': timeZones[index] + ' ' + moment.tz(timeZones[index]).format('Z')})
          //const element = array[index];
          
        }

        this.zonesValue = this.zonesOptions[0].id

        //countries.get(newValue).zones

        //console.log('newValue: ', newValue, this.zonesValue, this.zonesOptions, this.countriesMap, timeZones)

        this.forceTimeZonesRerender()

      } else {
        Object.values(zones).forEach(z => { 
          this.zonesOptions.push({ 'id': z.name, 'label': z.name + ' ' + moment.tz(z.name).format('Z')})
        });
        //console.log('newValue1: ', newValue)

      }
    },

    getInitalUploadEventItem () {
      this.$store.dispatch('getInitalUploadThemeItem', this.themeOptions)
    },

    preSaveEventItem () {

      if (this.filter.event !== '{}' && this.filter.locality !== '{}' && this.filter.clsfrs !== '{}') {
        this.saveEventItem ()
      } else {
        window.prompt('When a classifier is selected, at least one item must be selected. \n Please select a classifier item or deselect a classifier. ');
      }
    },

    viewEventItem () {
      emitter.emit('viewEvent', {id: this.eventId})
    },

    async saveEventItem () {
      try {
        // console.log('saveMyHibisItem: ', this.item.msrs[2].svl)

        this.item.time.tz = this.zonesValue

        let vl = moment(this.startTime).format()
        vl = vl.substring(0,19)
        this.item.time.vl = moment.tz(vl, this.zonesValue).format()
        
        let vle = moment(this.endTime).format()
        vle = vl.substring(0,11) + vle.substring(11,16) + vl.substring(16)
        this.item.time.vle = moment.tz(vle, this.zonesValue).format()


        if (this.imprtnt) {
          this.item.msrs.find( (itm) => itm.vid == ENV.MSR_ISIMPORTANT_ID).vl = 1
        } else {
          this.item.msrs.find( (itm) => itm.vid == ENV.MSR_ISIMPORTANT_ID).vl = 0
        }

        this.item.msrs.filter((item) => (item.vid == ENV.MSR_LINK_ITEMS_ID))[0].clctn = []
        for (let i=0; i < this.links.length ; i++) {
          let link = {}

          link.tp = 4
          link.vl = -7777777
          link.lbl = {name: this.links[i].name}
          link.rng = this.links[i].id
          link.svl = this.links[i].url
          link.isDlt = false

          this.item.msrs.filter((item) => (item.vid == ENV.MSR_LINK_ITEMS_ID))[0].clctn.push( link )
        }

        this.item.msrs.filter((item) => (item.vid == ENV.MSR_FILE_ITEMS_ID))[0].clctn = []
        for (let i=0; i < this.files.length ; i++) {
          let file = {}

          file.tp = 8
          file.vl = -7777777
          file.lbl = {name: this.files[i].name, uploaded: this.files[i].uploaded}
          file.rng = this.files[i].id
          file.svl = this.files[i].url
          file.isDlt = false

          this.item.msrs.filter((item) => (item.vid == ENV.MSR_FILE_ITEMS_ID))[0].clctn.push( file )
        }

        this.item.events.ids = '{0,' + this.filter.event.substring(1,this.filter.event.length-1)
          + '}'
        this.item.clsfrs.ids = '{0,' + this.filter.clsfrs.substring(1,this.filter.clsfrs.length-1)
          + '}'
        this.item.countries.ids = '{0,' + this.filter.locality.substring(1,this.filter.locality.length-1)
          + '}'

        this.outputHtml()

        let payload = {}
        payload.item = this.item
        payload.filter = this.filter
        payload.themeType = this.themeOptions.themeType
        payload.themeName = this.themeOptions.themeName

        await this.$store.dispatch('uploadThemeItem', payload)

        this.item = this.themesOptions.get('event').uploadData[0]

        emitter.emit('eventComponentFilter')

        this.forceRerender()

        //emitter.emit('saveEventsItem-done-emit')
      } catch (error) {
        //emitter.emit('saveEventsItem-done-emit')
        console.error('uploadThemeItems error:', error)
      }
    },

    forceTimeZonesRerender() {
      // Remove my-component from the DOM
      this.renderTimeZones = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderTimeZones = true
      })
    },

    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/events.scss';
@import '@/assets/events-upload.scss'
</style>
